
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <div id="new_task_dialog" class="vnctask-compose-dialog task__dialog open" *ngIf="isMobileDevice">
    <div id="new_task_dialog_header" class="task__dialog-header">

      <div class="mobile-back-button">
        <button mat-button (click)="goBack()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
      <div class="header_lbl disable-select">
        {{'NEW_TASK' | translate}}
      </div>
      <div class="desktop-close-button">
        <button mat-button (click)="goBack()">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="task__dialog-body add-task-options" [class.save_mode]="!submitting && !activeComponent">
      <vp-vnctask-compose-input [isVisible]="submitting" [optionComponents]="_optionComponents"
        (onValueChange)="onValueChange($event)" (onActiveComponentChange)="onActiveComponentChange($event)"
        (onAdd)="addTask()" (onRemoveTag)="removeTag($event)"
        (onRemoveWatcher)="removeWatcher($event)"></vp-vnctask-compose-input>
      <div id="options-container" class="vnctask-compose-dialog__options-container" [class.optionview]="activeComponent"
        [class.mainView]="activeComponent">
        <vp-vnctask-option-start-date [isVisible]="submitting" (onOpen)="openDialog($event)"
          (onClick)="onStartDateClick($event)" (onSelect)="onOptionClick($event)"
          [onMainView]="!activeComponent"></vp-vnctask-option-start-date>
        <vp-vnctask-option-due-date [isVisible]="submitting" (onOpen)="openDialog($event)"
          (onClick)="onCompletionDateClick($event)" (onSelect)="onOptionClick($event)"
          [onMainView]="!activeComponent"></vp-vnctask-option-due-date>
        <vp-vnctask-option-priority [isVisible]="submitting" (onOpen)="openDialog($event)"
          (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-priority>
        <vp-vnctask-option-link-project *ngIf="projectList.length > 1" [isVisible]="submitting"
          (onOpen)="openDialog($event)" (onSelect)="onOptionClick($event)"
          [onMainView]="!activeComponent"></vp-vnctask-option-link-project>
        <vp-vnctask-option-add-user [isVisible]="submitting" (onBack)="goBack()" (onOpen)="openDialog($event)"
          [onMainView]="!activeComponent" (onSelect)="onOptionClick($event)"
          [project]="selectedProject"></vp-vnctask-option-add-user>
        <vp-vnctask-option-link-list [isVisible]="submitting" (onOpen)="openDialog($event)"
          (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-link-list>
        <vp-vnctask-option-add-repeats [isVisible]="submitting" (onOpen)="openDialog($event)"
          (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-add-repeats>
        <vp-vnctask-option-add-estimate [isVisible]="submitting" (onBack)="goBack()" (onOpen)="openDialog($event)"
          (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-add-estimate>
        <vp-vnctask-option-add-tags [isVisible]="submitting" (onOpen)="openDialog($event)" (onBack)="goBack()"
          (onSelect)="onTagOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-add-tags>
        <vp-vnctask-option-link-location [isVisible]="submitting" (onOpen)="openDialog($event)"
          (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-link-location>
        <vp-vnctask-option-add-description [isVisible]="submitting" (onBack)="goBack()" (onOpen)="openDialog($event)"
          (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"></vp-vnctask-option-add-description>
        <vp-vnctask-option-add-watchers [isVisible]="submitting" (onBack)="goBack()" (onOpen)="openDialog($event)"
          [onMainView]="!activeComponent" (onSelect)="onWatcherOptionClick($event)"
          [project]="selectedProject"></vp-vnctask-option-add-watchers>
      </div>
    </div>
    <div class="task__dialog-footer">
      <div class="desktop-footer">
        <button mat-button (click)="goBack()">
          {{ 'CANCEL' | translate }}
        </button>
        <button mat-button [disabled]="submitting || activeComponent || addTaskInProgress" (click)="addTask()">
          {{ 'CREATE' | translate }}
        </button>
      </div>
      <div class="mobile-footer" *ngIf="!submitting && !activeComponent">
        <div class="title">
          {{ 'CREATE_TASK' | translate }}
        </div>
        <div class="save_footer_btn">
          <button mat-button [disabled]="addTaskInProgress" (click)="addTask()">
            <mat-icon>check</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
<div class="vnctask-loading" *ngIf="isOnIOS && isLoading">
  <div class="indicator">
    <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
  </div>
</div>


<div id="new_task_dialog" class="vnctask-compose-dialog task-dialog-new" *ngIf="!isMobileDevice">
   <vnc-header
    title="{{ 'NEW_TASK' | translate }}"
    type="title-close"
    size="l"
    (onCloseClick)="goBack()"
    [showCloseIcon]="true"
    >
    </vnc-header>

    <mat-divider ></mat-divider>

  <div class="task__dialog-body add-task-options new-task-body"
  style="position: relative;"
  [class.save_mode]="!submitting && !activeComponent" appDrag  (files)="filesDropped($event)">
    <vnc-input [required]="true" [showLabel]="true" [form]="taskName" placeholder="{{ 'NEW_TASK_PLACEHOLDER' | translate }}"></vnc-input>

    <div class="chip-container">
      <ng-container *ngFor="let item of allDataTag">
        <vnc-chips [text]="item.name" [removable]="true" type="dynamic" size="s" (removeIconClick)="removeData(item)" ></vnc-chips>
      </ng-container>
    </div>

    <div class="options-block-new vnctask-compose-dialog__options-container"
    #chatWindow
    (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)"
    >

    <div #chatDragDropShade class="chat-shade-drag-n-drop drop-data-area" [class.show-drop-file]="showOndragDiv"
    *ngIf="taskName.value || !submitting"
    >
      <div>
        <div class="chat-shade-drag-n-drop-container">{{'DROP_FILES_TO_SEND_THEM' | translate}}</div>
      </div>
    </div>

      <vp-new-vnctask-option-start-date [isVisible]="submitting" (onOpen)="openDialog($event)"
        (onClick)="onStartDateClick($event)" (selectedStartDate)="selectedStartDate($event)"
        (selectActivate)="selectActivate($event,'startDate')"
        [class.disable-click]="selectedData.startDate.name || !taskName.value || submitting"
        [onMainView]="!activeComponent" [ngStyle]="{'width': activatedData.startDate === true ? '100%' : 'auto' }"
        *ngIf="!activatedData.dueDate  && !activatedData.priority && !activatedData.user
        && !activatedData.repeat && !activatedData.estimate
        && !activatedData.listItem && !activatedData.location && !activatedData.tags && !activatedData.description && !activatedData.watcher && !activatedData.project"
        > </vp-new-vnctask-option-start-date>


        <vp-new-vnctask-option-due-date [isVisible]="submitting" (onOpen)="openDialog($event)"
        (onClick)="onCompletionDateClick($event)"
        (selectActivate)="selectActivate($event,'dueDate')"
        (selectedDueDate)="selectedDueDate($event)"
        [onMainView]="!activeComponent"
        [ngStyle]="{'width': activatedData.dueDate === true ? '100%' : 'auto' }"
        [class.disable-click]="selectedData.dueDate.name || !taskName.value || submitting"
        *ngIf="!activatedData.startDate && !activatedData.priority && !activatedData.user
        && !activatedData.repeat && !activatedData.estimate && !activatedData.listItem
         && !activatedData.location && !activatedData.tags && !activatedData.description && !activatedData.watcher && !activatedData.project"
        ></vp-new-vnctask-option-due-date>


        <vp-new-vnctask-option-priority [isVisible]="submitting" (onOpen)="openDialog($event)"
        (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"
        [ngStyle]="{'width': activatedData.priority === true ? '100%' : 'auto' }"
        [class.disable-click]="selectedData.priority.name || !taskName.value || submitting"
        (selectActivate)="selectActivate($event,'priority')"
        (selectedPriority)="selectedPriority($event)"
        *ngIf="!activatedData.startDate && !activatedData.dueDate && !activatedData.user  && !activatedData.repeat
        && !activatedData.estimate && !activatedData.listItem && !activatedData.location && !activatedData.tags
        && !activatedData.description && !activatedData.watcher && !activatedData.project"></vp-new-vnctask-option-priority>

<vp-new-vnctask-option-link-project
  [ngStyle]="{'width': activatedData.priority === true ? '100%' : 'auto' }"
  [class.disable-click]="selectedData.project.name || !taskName.value || submitting"
  (selectActivate)="selectActivate($event,'project')"
  (selectedProject)="selectedProjectData($event)"
  *ngIf="!activatedData.startDate && !activatedData.dueDate && !activatedData.user  && !activatedData.repeat
  && !activatedData.estimate && !activatedData.listItem && !activatedData.location && !activatedData.tags
  && !activatedData.description && !activatedData.watcher"

  ></vp-new-vnctask-option-link-project>
        <!-- <div class="icons-block" *ngIf="!activatedData.startDate  && !activatedData.priority
        && !activatedData.user  && !activatedData.repeat && !activatedData.estimate
        && !activatedData.listItem && !activatedData.location && !activatedData.tags && !activatedData.description && !activatedData.watcher">
          <div class="date-icon project-icon">
            <ng-container [ngTemplateOutlet]="projectIcon"></ng-container>
          </div>
          <span>Project</span>
        </div> -->

        <vp-new-vnctask-option-add-user [isVisible]="submitting" (onBack)="goBack()" (onOpen)="openDialog($event)"
        [onMainView]="!activeComponent" (onSelect)="onOptionClick($event)"
        [project]="selectedProject"
        [ngStyle]="{'width': activatedData.user === true ? '100%' : 'auto' }"
        [class.disable-click]="selectedData.user.name || !taskName.value || submitting"
        (selectActivate)="selectActivate($event,'user')"
        (selectedUser)="selectedUser($event)"
         *ngIf="!activatedData.startDate && !activatedData.dueDate
          && !activatedData.priority && !activatedData.repeat
          && !activatedData.estimate && !activatedData.listItem
          && !activatedData.location && !activatedData.tags && !activatedData.description && !activatedData.watcher && !activatedData.project"
         ></vp-new-vnctask-option-add-user>

         <vp-new-vnctask-option-link-list
         [ngStyle]="{'width': activatedData.listItem === true ? '100%' : 'auto' }"
         [class.disable-click]="selectedData.list.name || !taskName.value || submitting"
         (selectActivate)="selectActivate($event,'listItem')"
         (selectedList)="selectedList($event)"
         *ngIf="!activatedData.startDate && !activatedData.dueDate  && !activatedData.priority
         && !activatedData.user  && !activatedData.repeat && !activatedData.estimate
         && !activatedData.location && !activatedData.tags && !activatedData.description && !activatedData.watcher && !activatedData.project"
         ></vp-new-vnctask-option-link-list>

        <vp-new-vnctask-option-add-repeats [isVisible]="submitting" (onOpen)="openDialog($event)"
        (onSelect)="onOptionClick($event)" [onMainView]="!activeComponent"
        [ngStyle]="{'width': activatedData.repeat === true ? '100%' : 'auto' }"
        [class.disable-click]="selectedData.repeat.name || !taskName.value || submitting"
        (selectActivate)="selectActivate($event,'repeat')"
        (selectedRepeat)="selectedRepeat($event)"
        *ngIf="!activatedData.startDate && !activatedData.dueDate
        && !activatedData.priority && !activatedData.user && !activatedData.estimate
        && !activatedData.listItem && !activatedData.location && !activatedData.tags && !activatedData.description && !activatedData.watcher && !activatedData.project"
        ></vp-new-vnctask-option-add-repeats>


        <vp-new-vnctask-option-add-estimate [isVisible]="submitting"
        [ngStyle]="{'width': activatedData.estimate === true ? '100%' : 'auto' }"
        [class.disable-click]="selectedData.estimate.name || !taskName.value || submitting"
        (selectActivate)="selectActivate($event,'estimate')"
        (selectedEstimate)="selectedEstimate($event)"
        *ngIf="!activatedData.startDate && !activatedData.dueDate
        && !activatedData.priority && !activatedData.user  && !activatedData.repeat
        && !activatedData.listItem && !activatedData.location && !activatedData.tags && !activatedData.description && !activatedData.watcher && !activatedData.project"
        ></vp-new-vnctask-option-add-estimate>


        <vp-new-vnctask-option-add-tags
        [ngStyle]="{'width': activatedData.tags === true ? '100%' : 'auto' }"
        [class.disable-click]="!taskName.value || submitting"
        (selectActivate)="selectActivate($event,'tags')"
        (selectedTags)="selectedTags($event)"
        *ngIf="!activatedData.startDate && !activatedData.dueDate  && !activatedData.priority && !activatedData.user
        && !activatedData.repeat && !activatedData.estimate && !activatedData.listItem && !activatedData.location
        && !activatedData.description && !activatedData.watcher && !activatedData.project"
        ></vp-new-vnctask-option-add-tags>


        <vp-new-vnctask-option-link-location
        [ngStyle]="{'width': activatedData.location === true ? '100%' : 'auto' }"
        [class.disable-click]="selectedData.location.name || !taskName.value || submitting"
        (selectActivate)="selectActivate($event,'location')"
        (selectedLocation)="selectedLocation($event)"
        *ngIf="!activatedData.startDate && !activatedData.dueDate
        && !activatedData.priority && !activatedData.user  && !activatedData.repeat &&
        !activatedData.estimate && !activatedData.listItem && !activatedData.tags && !activatedData.description && !activatedData.watcher && !activatedData.project"
        ></vp-new-vnctask-option-link-location>

        <vp-new-vnctask-option-add-description
        [ngStyle]="{'width': activatedData.description === true ? '100%' : 'auto' }"
        [class.disable-click]="selectedData.description.name || !taskName.value || submitting"
        (selectActivate)="selectActivate($event,'description')"
        (selectedDescription)="selectedDescription($event)"
        *ngIf="!activatedData.startDate && !activatedData.dueDate  && !activatedData.priority && !activatedData.user  &&
        !activatedData.repeat && !activatedData.estimate && !activatedData.listItem && !activatedData.location && !activatedData.tags && !activatedData.watcher && !activatedData.project"
        ></vp-new-vnctask-option-add-description>

        <vp-new-vnctask-option-add-watchers
        [ngStyle]="{'width': activatedData.watcher === true ? '100%' : 'auto' }"
        [class.disable-click]="!taskName.value || submitting"
        (selectActivate)="selectActivate($event,'watcher')"
        (selectedWatcher)="selectedWatcher($event)"
        *ngIf="!activatedData.startDate && !activatedData.dueDate  && !activatedData.priority && !activatedData.user  &&
        !activatedData.repeat && !activatedData.estimate && !activatedData.listItem && !activatedData.location && !activatedData.tags && !activatedData.description && !activatedData.project"
        ></vp-new-vnctask-option-add-watchers>

        <div class="icons-block"
        [class.disable-click]="!taskName.value || submitting"
        *ngIf="!activatedData.startDate && !activatedData.dueDate  && !activatedData.priority && !activatedData.user  &&
        !activatedData.repeat && !activatedData.estimate && !activatedData.listItem && !activatedData.location && !activatedData.tags
         && !activatedData.description && !activatedData.watcher"
         (click)="uploadFileChooser.click()"
         >
          <div class="date-icon attachment-icon">
            <ng-container [ngTemplateOutlet]="attachmentIcon"></ng-container>
          </div>
          <span>{{'ATTACHMENT' | translate}}</span>
        </div>
        <input id="custom-file-input" #uploadFileChooser type="file" class="hide" (click)="uploadFileChooser.value = null;" (change)="fileRead($event)">

    </div>

  </div>
  <div class="task__dialog-footer">
    <div class="desktop-footer">
      <button mat-button (click)="cancelClick()" class="secondary-btn">
        {{ 'CANCEL' | translate }}
      </button>
      <button mat-button [disabled]="submitting || checkIfAnyActivate()"
      [class.disable-click-create]="submitting || checkIfAnyActivate()"
      (click)="preparePayloadForCreateTask()" class="primary-btn">
        {{ 'CREATE' | translate }}
      </button>
    </div>
    <div class="mobile-footer" *ngIf="!submitting && !activeComponent && isMobileDevice">
      <div class="title">
        {{ 'CREATE_TASK' | translate }}
      </div>
      <div class="save_footer_btn">
        <button mat-button [disabled]="addTaskInProgress" (click)="addTask()">
          <mat-icon>check</mat-icon>
        </button>
      </div>
    </div>
  </div>

</div>



<!-- Icons -->
<ng-template #startDateIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/date-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M15.5,3 C16.3284271,3 17,3.67157288 17,4.5 L17,5 L18,5 C19.5976809,5 20.9036609,6.24891996 20.9949073,7.82372721 L21,8 L21,18 C21,19.5976809 19.75108,20.9036609 18.1762728,20.9949073 L6,21 C4.40231912,21 3.09633912,19.75108 3.00509269,18.1762728 L3,18 L3,8 C3,6.40231912 4.24891996,5.09633912 5.82372721,5.00509269 L7,5.005 L7,4.5 C7,3.67157288 7.67157288,3 8.5,3 C9.32842712,3 10,3.67157288 10,4.5 L10,5.003 L14,5.002 L14,4.5 C14,3.67157288 14.6715729,3 15.5,3 Z M18,7 L6,7 C5.48716416,7 5.06449284,7.38604019 5.00672773,7.88337887 L5,8 L5,18 C5,18.5128358 5.38604019,18.9355072 5.88337887,18.9932723 L6,19 L18,19 C18.5128358,19 18.9355072,18.6139598 18.9932723,18.1166211 L19,18 L19,8 C19,7.44771525 18.5522847,7 18,7 Z M16.9804916,14 C17.5370911,14 17.9883041,14.451213 17.9883041,15.0078125 C17.9883041,15.564412 17.5370911,16.015625 16.9804916,16.015625 L7.01950841,16.015625 C6.46290893,16.015625 6.01169591,15.564412 6.01169591,15.0078125 C6.01169591,14.451213 6.46290893,14 7.01950841,14 L16.9804916,14 Z M7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 Z" id="Combined-Shape" fill="#FFFFFF"></path>
    </g>
  </svg>
</ng-template>

<ng-template #dueDateIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icon/product/date-confirmed-new</title>
    <g id="Icon/product/date-confirmed-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M15.5,3 C16.3284271,3 17,3.67157288 17,4.5 L17,5 L18,5 C19.5976809,5 20.9036609,6.24891996 20.9949073,7.82372721 L21,8 L21,18 C21,19.5976809 19.75108,20.9036609 18.1762728,20.9949073 L6,21 C4.40231912,21 3.09633912,19.75108 3.00509269,18.1762728 L3,18 L3,8 C3,6.40231912 4.24891996,5.09633912 5.82372721,5.00509269 L7,5.005 L7,4.5 C7,3.67157288 7.67157288,3 8.5,3 C9.32842712,3 10,3.67157288 10,4.5 L10,5.003 L14,5.002 L14,4.5 C14,3.67157288 14.6715729,3 15.5,3 Z M18,7 L6,7 C5.48716416,7 5.06449284,7.38604019 5.00672773,7.88337887 L5,8 L5,18 C5,18.5128358 5.38604019,18.9355072 5.88337887,18.9932723 L6,19 L18,19 C18.5128358,19 18.9355072,18.6139598 18.9932723,18.1166211 L19,18 L19,8 C19,7.44771525 18.5522847,7 18,7 Z M16.0710678,9.29289322 C16.4615921,9.68341751 16.4615921,10.3165825 16.0710678,10.7071068 L10.8284271,15.9497475 C10.4379028,16.3402718 9.80473785,16.3402718 9.41421356,15.9497475 L7.29289322,13.8284271 C6.90236893,13.4379028 6.90236893,12.8047379 7.29289322,12.4142136 C7.68341751,12.0236893 8.31658249,12.0236893 8.70710678,12.4142136 L10.1213203,13.8270129 L14.6568542,9.29289322 C15.0473785,8.90236893 15.6805435,8.90236893 16.0710678,9.29289322 Z" id="Combined-Shape" fill="#FFFFFF"></path>
    </g>
</svg>
</ng-template>

<ng-template #priorityIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/flag-outline-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(5.000000, 4.000000)" fill="#FFFFFF">
              <path d="M2,16 C2,16.5522847 1.55228475,17 1,17 C0.44771525,17 0,16.5522847 0,16 L0,0.995397568 C0,0.494216766 0.385092325,0.0655238361 0.881209867,0.0068390339 L0.997544646,0 L9,0 L9.4,2 L13.9988322,2 C14.5204527,2 14.9366012,2.38426347 14.9933897,2.87931318 L15,2.99539757 L15,11.0046024 C15,11.5057832 14.612829,11.9344762 14.1140334,11.993161 L13.9970707,12 L8,12 L7.6,10 L2,10 L2,16 Z M7.36,2 L2,2 L2.0007381,7.99891275 L9.23960781,8 L9.64,10 L13,10 L13,4 L7.76039219,4 L7.36,2 Z" id="Combined-Shape"></path>
          </g>
      </g>
  </svg>
</ng-template>


<ng-template #projectIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/book-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M18.9414862,3.0330051 C19.6933618,2.91470914 20.4630117,3.11745978 21.0505367,3.59206515 C21.5810884,4.02064714 21.9183658,4.635282 21.9909431,5.35555601 L21.9909431,5.35555601 L22,5.55817853 L22,18.1404996 C22.0007958,19.306033 21.1935303,20.311982 20.0124131,20.6213744 L20.0124131,20.6213744 L19.8270163,20.6607539 L12.4045654,21.9448636 L12.302659,21.9580291 L12.2,21.9624284 L11.7816664,21.9607873 L11.728059,21.958758 L11.5953178,21.9448195 L4.17273223,20.6599312 C3.00867729,20.4582401 2.11887542,19.5261959 2.00760453,18.3276055 L2.00760453,18.3276055 L2,18.1411826 L2,5.55817853 C2,4.79582129 2.34989753,4.07639678 2.94946297,3.59206514 C3.47023546,3.17138279 4.13610415,2.96288263 4.85425414,3.00943611 L4.85425414,3.00943611 L5.0585106,3.03300465 L12,4.125 Z M19.3700981,5.00002853 L19.2523285,5.00870171 L12,6.14972139 L4.79366842,5.01484851 L4.68163601,5.0014326 C4.50634363,4.9903327 4.33473086,5.04406895 4.20624279,5.14786219 C4.07276544,5.25568579 4,5.40529886 4,5.55817853 L4,5.55817853 L3.99891518,18.0946159 L4.00215337,18.187692 C4.02463036,18.4274308 4.22714549,18.6395601 4.51402306,18.6892662 L4.51402306,18.6892662 L11.9364548,19.9741382 L12.0636231,19.9741382 L19.4440442,18.6982304 L19.545787,18.6770422 C19.821008,18.6047276 20.0001639,18.381478 20,18.1411826 L20,18.1411826 L20.0013056,5.60926234 L19.9974696,5.50869106 C19.9835795,5.37329267 19.9125215,5.24380079 19.7937569,5.14786218 C19.6483122,5.03037134 19.4487444,4.97779872 19.2523285,5.00870171 Z M12,7 C12.5522847,7 13,7.44771525 13,8 L13,18 C13,18.5522847 12.5522847,19 12,19 C11.4477153,19 11,18.5522847 11,18 L11,8 C11,7.44771525 11.4477153,7 12,7 Z" id="Combined-Shape" fill="#FFFFFF"></path>
    </g>
  </svg>
</ng-template>

<ng-template #projectIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/book-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M18.9414862,3.0330051 C19.6933618,2.91470914 20.4630117,3.11745978 21.0505367,3.59206515 C21.5810884,4.02064714 21.9183658,4.635282 21.9909431,5.35555601 L21.9909431,5.35555601 L22,5.55817853 L22,18.1404996 C22.0007958,19.306033 21.1935303,20.311982 20.0124131,20.6213744 L20.0124131,20.6213744 L19.8270163,20.6607539 L12.4045654,21.9448636 L12.302659,21.9580291 L12.2,21.9624284 L11.7816664,21.9607873 L11.728059,21.958758 L11.5953178,21.9448195 L4.17273223,20.6599312 C3.00867729,20.4582401 2.11887542,19.5261959 2.00760453,18.3276055 L2.00760453,18.3276055 L2,18.1411826 L2,5.55817853 C2,4.79582129 2.34989753,4.07639678 2.94946297,3.59206514 C3.47023546,3.17138279 4.13610415,2.96288263 4.85425414,3.00943611 L4.85425414,3.00943611 L5.0585106,3.03300465 L12,4.125 Z M19.3700981,5.00002853 L19.2523285,5.00870171 L12,6.14972139 L4.79366842,5.01484851 L4.68163601,5.0014326 C4.50634363,4.9903327 4.33473086,5.04406895 4.20624279,5.14786219 C4.07276544,5.25568579 4,5.40529886 4,5.55817853 L4,5.55817853 L3.99891518,18.0946159 L4.00215337,18.187692 C4.02463036,18.4274308 4.22714549,18.6395601 4.51402306,18.6892662 L4.51402306,18.6892662 L11.9364548,19.9741382 L12.0636231,19.9741382 L19.4440442,18.6982304 L19.545787,18.6770422 C19.821008,18.6047276 20.0001639,18.381478 20,18.1411826 L20,18.1411826 L20.0013056,5.60926234 L19.9974696,5.50869106 C19.9835795,5.37329267 19.9125215,5.24380079 19.7937569,5.14786218 C19.6483122,5.03037134 19.4487444,4.97779872 19.2523285,5.00870171 Z M12,7 C12.5522847,7 13,7.44771525 13,8 L13,18 C13,18.5522847 12.5522847,19 12,19 C11.4477153,19 11,18.5522847 11,18 L11,8 C11,7.44771525 11.4477153,7 12,7 Z" id="Combined-Shape" fill="#FFFFFF"></path>
    </g>
  </svg>
</ng-template>

<ng-template #userIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/user-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M6,17.85 L6,19 C6,19.5522847 5.55228475,20 5,20 C4.44771525,20 4,19.5522847 4,19 L4,17.85 L4,17.85 C4,14.8766994 8.5673946,13 12.5,13 C16.3506761,13 20.8099674,14.7993182 20.9940999,17.6656036 L21,17.85 L21,19 C21,19.5522847 20.5522847,20 20,20 C19.4477153,20 19,19.5522847 19,19 L19,17.85 L19,17.85 C19,16.540378 15.79428,15.1542503 12.9670805,15.0119699 L12.7324336,15.0030208 L12.5,15 C9.64886306,15 6.18754404,16.3913943 6.00734383,17.740716 L6,17.85 Z M12.507014,3 C14.9932903,3 17,5.01382241 17,7.5 C17,9.98617759 14.9932903,12 12.507014,12 C10.0193052,12 8,9.98473341 8,7.5 C8,5.01526659 10.0193052,3 12.507014,3 Z M12.507014,5 C11.1229794,5 10,6.12073341 10,7.5 C10,8.87926659 11.1229794,10 12.507014,10 C13.8871336,10 15,8.88318908 15,7.5 C15,6.11681092 13.8871336,5 12.507014,5 Z"
        id="Shape" fill="#FFFFFF"></path>
    </g>
  </svg>
</ng-template>

<ng-template #repeatIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/repeat" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icon-24px" transform="translate(3.000000, 2.000000)" fill="#FFFFFF">
        <path
          d="M4,5 L14,5 L14,8 L18,4 L14,0 L14,3 L2,3 L2,9 L4,9 L4,5 L4,5 Z M14,15 L4,15 L4,12 L0,16 L4,20 L4,17 L16,17 L16,11 L14,11 L14,15 L14,15 Z"
          id="Shape"></path>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #estimateIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/time-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M11,6.0078125 C11.5565995,6.0078125 12.0078125,6.45902553 12.0078125,7.015625 L12.007,12 L15.9921875,12 C16.548787,12 17,12.451213 17,13.0078125 C17,13.564412 16.548787,14.015625 15.9921875,14.015625 L11.0078125,14.015625 C10.7465411,14.015625 10.5084909,13.9162037 10.3294306,13.7531299 C10.1225458,13.5678976 9.9921875,13.2991722 9.9921875,13 L9.9921875,7.015625 C9.9921875,6.45902553 10.4434005,6.0078125 11,6.0078125 Z"
        id="Combined-Shape" fill="#FFFFFF"></path>
    </g>
  </svg>
</ng-template>

<ng-template #tagsIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/tag-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M11,2 C11.5,2 11.9259259,2.14814815 12.2777778,2.44444444 L12.40625,2.5625 L21.40625,11.5625 C21.78125,11.9375 21.96875,12.40625 21.96875,12.96875 C21.96875,13.46875 21.8206019,13.8946759 21.5243056,14.2465278 L21.40625,14.375 L14.375,21.40625 C14,21.78125 13.53125,21.96875 12.96875,21.96875 C12.46875,21.96875 12.0428241,21.8206019 11.6909722,21.5243056 L11.5625,21.40625 L2.5625,12.40625 C2.22916667,12.0729167 2.04398148,11.6655093 2.00694444,11.1840278 L2,11 L2,3.96875 C2,3.40625 2.1875,2.9375 2.5625,2.5625 C2.89583333,2.22916667 3.30324074,2.04398148 3.78472222,2.00694444 L3.96875,2 L11,2 Z M11.013,3.997 L11,4 L4,4 L4,11 L3.997,11.013 L12.954,19.97 L12.96875,19.96875 L12.982,19.97 L19.97,12.982 L19.96875,12.96875 L19.97,12.954 L11.013,3.997 Z M6.4921875,5.015625 C6.9140625,5.015625 7.265625,5.15625 7.546875,5.4375 C7.828125,5.71875 7.96875,6.0703125 7.96875,6.4921875 C7.96875,6.9140625 7.828125,7.265625 7.546875,7.546875 C7.265625,7.828125 6.90625,7.96875 6.46875,7.96875 C6.0625,7.96875 5.71875,7.828125 5.4375,7.546875 C5.15625,7.265625 5.015625,6.9140625 5.015625,6.4921875 C5.015625,6.0703125 5.15625,5.71875 5.4375,5.4375 C5.71875,5.15625 6.0703125,5.015625 6.4921875,5.015625 Z"
        id="Combined-Shape" fill="#FFFFFF"></path>
    </g>
  </svg>
</ng-template>


<ng-template #descriptionIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/edit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icon-24px" transform="translate(3.000000, 2.997500)" fill="#FFFFFF">
        <path
          d="M0,14.2525 L0,18.0025 L3.75,18.0025 L14.81,6.9425 L11.06,3.1925 L0,14.2525 L0,14.2525 Z M17.71,4.0425 C18.1,3.6525 18.1,3.0225 17.71,2.6325 L15.37,0.2925 C14.98,-0.0975 14.35,-0.0975 13.96,0.2925 L12.13,2.1225 L15.88,5.8725 L17.71,4.0425 L17.71,4.0425 Z"
          id="Shape"></path>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #attachmentIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/attachment" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Icon-24px" transform="translate(2.000000, 7.000000)" fill="#FFFFFF">
        <path
          d="M0,5.5 C0,2.46 2.46,0 5.5,0 L16,0 C18.21,0 20,1.79 20,4 C20,6.21 18.21,8 16,8 L7.5,8 C6.12,8 5,6.88 5,5.5 C5,4.12 6.12,3 7.5,3 L15,3 L15,5 L7.41,5 C6.86,5 6.86,6 7.41,6 L16,6 C17.1,6 18,5.1 18,4 C18,2.9 17.1,2 16,2 L5.5,2 C3.57,2 2,3.57 2,5.5 C2,7.43 3.57,9 5.5,9 L15,9 L15,11 L5.5,11 C2.46,11 0,8.54 0,5.5 L0,5.5 Z"
          id="Shape"></path>
      </g>
    </g>
  </svg>
</ng-template>






