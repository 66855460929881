
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { environment } from "../../../environments/environment";
import * as moment from "moment";
import _ from "lodash";
import * as md5 from "blueimp-md5";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";

export function mobileonly(target: Object, method: string, descriptor: PropertyDescriptor) {
  let originalMethod = descriptor.value;
  descriptor.value = function(...args){
    if (!CommonUtil.isOnNativeMobileDevice()) {
      return;
    }
    return originalMethod.apply(this, args);
  };
}

export class CommonUtil {
  // PRASHANT_COMMENT common helper methods here
  static imagesExtensions = ["jpeg", "jpg", "gif", "png", "bmp", "svg"];
  static audioExtensions = ["mp3", "wav"];

  static randomId(length: number = 7, containNumbers: boolean = true) {
    let randomId = "";
    let dictionary = "abcdefghijklmnopqrstuvwxyz";

    if (containNumbers) {
      dictionary += "0123456789";
    }

    const dictionaryLength = dictionary.length;

    for (let i = 0; i < length; i++) {
      randomId += dictionary.charAt(Math.floor(Math.random() * dictionaryLength));
    }

    return randomId;
  }

  static getRandomNumbers() {
    let array = new Uint32Array(10);
    window.crypto.getRandomValues(array);
    return array.join("");
  }

  static escapeHTMLString(unescapedString: string): string {
    return unescapedString
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  static escapeCarriagesAndNewline(unescapedString: string): string {
    return unescapedString.replace(/\r?\n/g, " ");
  }

  static linkify(inputText: string): string {
    let replacedText, replacePattern1, replacePattern2, replacePattern3;

    // URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, "<a href=\"$1\" target=\"_blank\" class=\"open-new-window\">$1</a>");

    // URLs starting with "www." (without // before it, or it"d re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[-a-zA-Z0-9@:%._\+~#=]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, "$1<a href=\"http://$2\" target=\"_blank\" class=\"open-new-window\">$2</a>");

    // Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, "<a href=\"mailto:$1\">$1</a>");

    return replacedText;
  }

  static escapeColonURLs(inputText: string): string {
    let replacedText, replacePattern1, replacePattern2;

    // URLs starting with http://, https://, or ftp://
    replacePattern1 = /\"(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])\":/gim;
    replacedText = inputText.replace(replacePattern1, "");

    replacePattern2 = /(^|[^\/])\"(www\.[\S]+)\":/gim;
    replacedText = replacedText.replace(replacePattern2, "");

    return replacedText;
  }

  static validateEmail(email) {
    // RFC822 version
    let sQtext = "[^\\x0d\\x22\\x5c\\x80-\\xff]";
    let sDtext = "[^\\x0d\\x5b-\\x5d\\x80-\\xff]";
    let sAtom = "[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+";
    let sQuotedPair = "\\x5c[\\x00-\\x7f]";
    let sDomainLiteral = "\\x5b(" + sDtext + "|" + sQuotedPair + ")*\\x5d";
    let sQuotedString = "\\x22(" + sQtext + "|" + sQuotedPair + ")*\\x22";
    let sDomain_ref = sAtom;
    let sSubDomain = "(" + sDomain_ref + "|" + sDomainLiteral + ")";
    let sWord = "(" + sAtom + "|" + sQuotedString + ")";
    let sDomain = sSubDomain + "(\\x2e" + sSubDomain + ")*";
    let sLocalPart = sWord + "(\\x2e" + sWord + ")*";
    let sAddrSpec = sLocalPart + "\\x40" + sDomain; // complete RFC822 email address spec
    let sValidEmail = "^" + sAddrSpec + "$"; // as whole string

    let reValidEmail = new RegExp(sValidEmail);

    return reValidEmail.test(email);
  }

  static logger(data: any) { }

  static isOnMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|PlayBook/i
      .test(navigator.userAgent);
  }

  static isOnIOS() {
    return typeof device !== "undefined" && device.platform.toUpperCase() === "IOS";
  }

  static isOnAndroid() {
    return typeof device !== "undefined" && device.platform.toUpperCase() === "ANDROID";
  }

  static isOnNativeMobileDevice(){
    return CommonUtil.isOnIOS() || CommonUtil.isOnAndroid();
  }

  static isInsideIFrame() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static isOnIpad() {
    return /iPad/i
      .test(navigator.userAgent);
  }

  static getBaseUrl() {
    const baseUrl = window.location.href;

    if (environment.isCordova) {
        return baseUrl.split("/www/")[0] + "/www";
    } else if (environment.isElectron) {
        return baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/task")[0];
    } else {
        return "";
    }
  }

  static getFullUrl(url: string) {
    return CommonUtil.getBaseUrl() + url;
  }

  static isAudio(fileType: string): boolean {
    return this.audioExtensions.indexOf(fileType.toLowerCase()) !== -1;
  }

  static isImage(fileType: string): boolean {
    return this.imagesExtensions.indexOf(fileType.toLowerCase()) !== -1;
  }

  static getMediaType(body: string): string {
    if (body) {
      body = body.toLowerCase();
    }

    if ((body.lastIndexOf("aac") === body.length - 3) ||
      (body.lastIndexOf("amr") === body.length - 3) ||
      (body.lastIndexOf("aiff") === body.length - 4) ||
      (body.lastIndexOf("flac") === body.length - 4) ||
      (body.lastIndexOf("mp3") === body.length - 3) ||
      (body.lastIndexOf("oog") === body.length - 3) ||
      (body.lastIndexOf("wma") === body.length - 3) ||
      (body.lastIndexOf("wav") === body.length - 3)) {
      return MediaType.VOICE_MESSAGE;
    }

    if ((body.lastIndexOf("jpeg") === body.length - 4) ||
      (body.lastIndexOf("jpg") === body.length - 3) ||
      (body.lastIndexOf("gif") === body.length - 3) ||
      (body.lastIndexOf("png") === body.length - 3) ||
      (body.lastIndexOf("PNG") === body.length - 3) ||
      (body.lastIndexOf("bmp") === body.length - 3) ||
      (body.lastIndexOf("svg") === body.length - 3) ||
      (body.lastIndexOf("tiff") === body.length - 4) ||
      (body.lastIndexOf("ico") === body.length - 3) ||
      (body.lastIndexOf("odi") === body.length - 3)) {
      return MediaType.IMAGE;
    }

    if ((body.lastIndexOf("3gp") === body.length - 3) ||
      (body.lastIndexOf("avi") === body.length - 3) ||
      (body.lastIndexOf("flv") === body.length - 3) ||
      (body.lastIndexOf("m4v") === body.length - 3) ||
      (body.lastIndexOf("mkv") === body.length - 3) ||
      (body.lastIndexOf("mov") === body.length - 3) ||
      (body.lastIndexOf("mp4") === body.length - 3) ||
      (body.lastIndexOf("mpeg") === body.length - 4) ||
      (body.lastIndexOf("mpg") === body.length - 3) ||
      (body.lastIndexOf("mts") === body.length - 3) ||
      (body.lastIndexOf("rm") === body.length - 2) ||
      (body.lastIndexOf("vob") === body.length - 3) ||
      (body.lastIndexOf("wmv") === body.length - 3)) {
      return MediaType.VIDEOS;
    }

    if (body.lastIndexOf("pdf") === body.length - 3) {
      return MediaType.PDF;
    }

    if ((body.lastIndexOf("txt") === body.length - 3) ||
      (body.lastIndexOf("rtf") === body.length - 3) ||
      (body.lastIndexOf("dat") === body.length - 3)) {
      return MediaType.TEXT;
    }

    if ((body.lastIndexOf("doc") === body.length - 3) ||
      (body.lastIndexOf("docx") === body.length - 4) ||
      (body.lastIndexOf("docm") === body.length - 4) ||
      (body.lastIndexOf("odt") === body.length - 3)) {
      return MediaType.DOC;
    }

    if ((body.lastIndexOf("xls") === body.length - 3) ||
      (body.lastIndexOf("xlr") === body.length - 3) ||
      (body.lastIndexOf("xlsx") === body.length - 4) ||
      (body.lastIndexOf("xlsm") === body.length - 3) ||
      (body.lastIndexOf("ods") === body.length - 3) ||
      (body.lastIndexOf("csv") === body.length - 3) ||
      (body.lastIndexOf("tsv") === body.length - 3)) {
      return MediaType.EXCEL;
    }

    if ((body.lastIndexOf("ppt") === body.length - 3) ||
      (body.lastIndexOf("pptx") === body.length - 4) ||
      (body.lastIndexOf("pps") === body.length - 3) ||
      (body.lastIndexOf("odp") === body.length - 3)) {
      return MediaType.PPT;
    }

    if ((body.lastIndexOf("rar") === body.length - 3) ||
      (body.lastIndexOf("tar") === body.length - 3)) {
      return MediaType.RAR;
    }

    if ((body.lastIndexOf("zip") === body.length - 3) ||
      (body.lastIndexOf("7z") === body.length - 2)) {
      return MediaType.ZIP;
    }

    return MediaType.ATTACHMENTS;
  }

  static getMediaUrl(type: string): string {
    let url = "";

    if (type === MediaType.VOICE_MESSAGE) {
      url = "/assets/media-icons/file-document-mic.svg";
    }
    if (type === MediaType.VIDEOS) {
      url = "/assets/media-icons/file-document-video.svg";
    }
    if (type === MediaType.IMAGE) {
      url = "/assets/media-icons/file-document-image.svg";
    }
    if (type === MediaType.PDF) {
      url = "/assets/media-icons/file-pdf-box.svg";
    }
    if (type === MediaType.DOC) {
      url = "/assets/media-icons/icons8-microsoft-word.svg";
    }
    if (type === MediaType.RAR || type === MediaType.ZIP) {
      url = "/assets/media-icons/zip-box.svg";
    }
    if (type === MediaType.EXCEL) {
      url = "/assets/media-icons/icons8-microsoft-excel.svg";
    }
    if (type === MediaType.TEXT) {
      url = "/assets/media-icons/file-document-box.svg";
    }
    if (type === MediaType.PPT) {
      url = "/assets/media-icons/icons8-microsoft-powerpoint.svg";
    }
    if (type === MediaType.ATTACHMENTS) {
      url = "/assets/media-icons/file-document-box.svg";
    }

    return this.getFullUrl(url);
  }

  static trimString(subject: string, length: number, suffix: string = "...") {
    if (subject.length <= length) {
      return subject;
    }

    const suffixLength = suffix.length;
    const trimLength = length - suffixLength;

    return subject.slice(0, trimLength - 1) + suffix;
  }

  static isToday(momentDate) {
    let yesterday = moment().clone().startOf("day");
    return momentDate.isSame(yesterday , "d");
  }

  static isYesterday(momentDate) {
    let yesterday = moment().clone().subtract(1, "days").startOf("day");
    return momentDate.isSame(yesterday , "d");
  }

  static getTimeAgo(timestamp) {
    return moment(timestamp).fromNow();
  }

  static getTimeAgoWithLocale(timestamp, locale) {
    moment.locale(locale);
    return moment(timestamp).fromNow();
  }

  static hideBackdrop() {
    // mdl-backdrop-overlay
    if (document.querySelector("mdl-backdrop-overlay") !== null) {
      (<HTMLElement>document.querySelector("mdl-backdrop-overlay")).style.display = "none";
    }
    if (CommonUtil.isOnMobileDevice() && document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "none";
    }
  }

  static showBackdrop() {
    // mdl-backdrop-overlay
    if (document.querySelector("mdl-backdrop-overlay") !== null) {
      (<HTMLElement>document.querySelector("mdl-backdrop-overlay")).style.display = "block";
    }
    if (CommonUtil.isOnMobileDevice() && document.querySelector(".dialog-backdrop") !== null) {
      (<HTMLElement>document.querySelector(".dialog-backdrop")).style.display = "block";
    }
  }

  static getIcon(appName: string): string {
    let icon = "";
    let name = appName.replace("vnc", "VNC");
    switch (appName.toLowerCase()) {
      case "vncmail": icon = CommonUtil.getFullUrl("/assets/images/VNCmail.svg"); break;
      case "vnctask": icon = CommonUtil.getFullUrl("/assets/images/VNCtask.svg"); break;
      case "vncmcb": icon = CommonUtil.getFullUrl("/assets/images/VNCmcb.svg"); break;
      case "vnccontacts": icon = CommonUtil.getFullUrl("/assets/images/VNCcontacts.svg"); break;
      case "vnctalk": icon = CommonUtil.getFullUrl("/assets/images/VNCtalk.svg"); break;
      case "vnccalendar": icon = CommonUtil.getFullUrl("/assets/images/VNCcalendar.svg"); break;
      case "vncproject": icon = CommonUtil.getFullUrl("/assets/images/VNCproject.svg"); break;
      default: icon = CommonUtil.getFullUrl("/assets/images/" + name + ".svg"); break;
    }
    return icon;
  }

  static getPackageName(appName: string): string {
    const packageName = `biz.vnc.${appName.toLowerCase()}`;
    console.log("getPackageName", appName, packageName);
    return packageName;
  }

  static getAppUrl(appName: string): string {
    const availableiOSApps = {
      "vncmail": "",
      "vnctask": "itms-apps://itunes.apple.com/app/id1423089930",
      "vnccontacts": ""
    };
    const availableAndroidApps = {
      "vncmail": "",
      "vnctask": "market://details?id=biz.vnc.vnctask",
      "vnccontacts": ""
    };
    if (!CommonUtil.isOnAndroid()) {
      return availableiOSApps[appName.toLowerCase()];
    }
    return availableAndroidApps[appName.toLowerCase()];
  }

  static strip_html_tags(str: string): string {
    if ((str === null) || (str === "")) {
      return str;
    } else {
      return str.replace(/<[^>]*>/g, "");
    }
  }

  static parseMentions(text: string): string[] {
    if (!text) {
      return [];
    }
    const mentionsRegex = new RegExp("@([a-zA-Z0-9\_\.\-]+[@]*[a-zA-Z0-9\_\.\-]+)", "gim");
    let matches = text.match(mentionsRegex);
    if (matches && matches.length) {
        matches = matches.map((match) => {
            return match.slice(1);
        });
        return _.uniq(matches);
    } else {
        return [];
    }
  }

  static openUrl(url) {
    if (url) {
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.setAttribute("class", "open-new-window");
      a.setAttribute("target", "_blank");
      a.href = url;
      a.click();
      a.remove();
    }
  }

  static isSQLSupported() {
    return this.isOnNativeMobileDevice() || environment.isElectron;
    // return this.isOnNativeMobileDevice();
  }

  static getAvatarText(text: string): string {
    if (!text) {
      return "";
    }
    text = text.split("@")[0].replace(/\./g, " ").replace(/\W-_+/g, "");
    const str = text.split(/\s+/);
    if (str.length > 1) {
      return str[0].substr(0, 1).toUpperCase() + str[1].substr(0, 1).toUpperCase();
    }
    return text.substr(0, 2).toUpperCase();
  }


  static getBgColor(text: string): string {
    let color;
    switch (text) {
      case "A" || "a":
      case "Q" || "q":
        color = "#f44336";
        break;
      case "B" || "b":
      case "R" || "r":
        color = "#e91e63";
        break;
      case "C" || "c":
      case "S" || "s":
        color = "#9c27b0";
        break;
      case "D" || "d":
      case "T" || "t":
        color = "#673ab7";
        break;
      case "E" || "e":
      case "U" || "u":
        color = "#3f51b5";
        break;
      case "F" || "f":
      case "V" || "v":
        color = "#2196f3";
        break;
      case "G" || "g":
      case "W" || "w":
        color = "#03a9f4";
        break;
      case "H" || "h":
      case "X" || "x":
        color = "#00bcd4";
        break;
      case "I" || "i":
      case "Y" || "y":
        color = "#009688";
        break;
      case "J" || "j":
      case "Z" || "z":
        color = "#4caf50";
        break;
      case "K" || "k":
        color = "#8bc34a";
        break;
      case "L" || "l":
        color = "#ff9800";
        break;
      case "M" || "m":
        color = "#ff5722";
        break;
      case "N" || "n":
        color = "#795548";
        break;
      case "O" || "l":
        color = "#9e9e9e";
        break;
      case "P" || "p":
        color = "#607d8b";
        break;
      default:
        color = "#888888";
        break;
    }
    return color;
  }

  static buildTargetHash(target) {
    return md5(target);
  }

  static isFileSystemUrl(url: string) {
    return url.includes("file://");
  }

  static getRandomAvatarColor() {
    const colors = this.getAvatarbgColors();
    const random = Math.floor(Math.random() * colors.length);
    return colors[random];
  }

  static getAvatarbgColors() {
    return [
      "#F44336",
      "#E91E63",
      "#9C27B0",
      "#673AB7",
      "#3F51B5",
      "#2196F3",
      "#03A9F4",
      "#00BCD4",
      "#009688",
      "#4CAF50",
      "#8BC34A",
      "#FF9800",
      "#FF5722",
      "#795548",
      "#9E9E9E",
      "#607D8B"
    ];
  }

  static getCharacters(firstName, lastName) {
    if (firstName && firstName !== undefined && lastName && lastName !== undefined) {
      const fName: string = firstName.trim();
      const lName: string = lastName.trim();
      const chr1 = fName.length > 0 ? fName.charAt(0) : "";
      const chr2 = lName.length > 0 ? lName.charAt(0) : "";
      return (chr1 + chr2).trim();
    } else if (firstName && firstName !== undefined) {
      const fName: string = firstName.trim();
      const chr1 = fName.length > 0 ? fName.charAt(0) : "";
      return chr1;
    } else if (lastName && lastName !== undefined) {
      const lName: string = lastName.trim();
      const chr2 = lName.length > 0 ? lName.charAt(0) : "";
      return chr2;
    } else {
      return "NA";
    }
  }
  static getBase6ImageFromUrl(url) {
    const subject = new Subject();
    const image: HTMLImageElement = new Image();
    image.src = url;
    image.crossOrigin = "Anonymous";
    image.onload = () => {
      const canvas: HTMLCanvasElement = document.createElement("canvas");
      const context: CanvasRenderingContext2D = canvas.getContext("2d");
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      const b64url = canvas.toDataURL();
      subject.next(b64url);
    };
    image.onerror = (error) => {
      subject.error(error);
    };
    return subject.asObservable().pipe(take(1));
  }
}

export enum MediaType {
  IMAGE = "image",
  VOICE_MESSAGE = "voice-messages",
  ATTACHMENTS = "attachments",
  VIDEOS = "videos",
  ALL = "all",
  PDF = "pdf",
  DOC = "doc",
  TEXT = "txt",
  EXCEL = "excel",
  PPT = "ppt",
  RAR = "rar",
  ZIP = "zip"
}

export enum MediaLongPressAction {
  VIEW = "view",
  SHARE = "share",
  DOWNLOAD = "download",
  DELETE = "delete"
}
