
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <!-- <div id="estimate_option" class="option-icon" *ngIf="onMainView" [class.selected]="value || isVisible" (click)="activate = !activate">
    <mat-icon>access_time</mat-icon>
    <p>{{ 'ESTIMATED_TIME' | translate }}</p>
  </div> -->
  <ng-template #estimateIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/time-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M11,6.0078125 C11.5565995,6.0078125 12.0078125,6.45902553 12.0078125,7.015625 L12.007,12 L15.9921875,12 C16.548787,12 17,12.451213 17,13.0078125 C17,13.564412 16.548787,14.015625 15.9921875,14.015625 L11.0078125,14.015625 C10.7465411,14.015625 10.5084909,13.9162037 10.3294306,13.7531299 C10.1225458,13.5678976 9.9921875,13.2991722 9.9921875,13 L9.9921875,7.015625 C9.9921875,6.45902553 10.4434005,6.0078125 11,6.0078125 Z"
          id="Combined-Shape" fill="#FFFFFF"></path>
      </g>
    </svg>
  </ng-template>

  <div class="icons-block" *ngIf="!activate">
    <div class="date-icon estimate-icon"  (click)="selectActivateData()">
      <ng-container [ngTemplateOutlet]="estimateIcon"></ng-container>
    </div>
    <span>Estimate</span>
  </div>

  <div id="repeat_option_list" class="option-list add-task-estimate-time" *ngIf="active || activate">
    <div class="estimate-save-close">
      <span>{{ 'ESTIMATED_TIME' | translate }}:</span>
      <div class="icons-div">
        <mdl-icon class="icon-close" (click)="cancelActivateData()" id="estimate_close">clear</mdl-icon>
        <mdl-icon class="icon-check" (click)="setEstimatedTime()" id="estimate_save">check_circle</mdl-icon>
      </div>
    </div>
    <div class="estimate-time-label">
      <div>{{ 'HOURS' | translate }}</div>
      <div>{{ 'MINUTES' | translate }}</div>
    </div>
    <div>
    <input class="add-task-estimate-input" [(ngModel)]="estimatedHours" (keydown)="validateNumber($event, estimatedHours)" type="text" value="0" id="estimate_hour" maxlength="2" inputmode="numeric" pattern="[0-9]*">
    <input class="add-task-estimate-input" [(ngModel)]="estimatedMinutes" (keydown)="validateNumber($event, estimatedMinutes)" type="text" value="0" id="estimate_minute" maxlength="2" inputmode="numeric" pattern="[0-9]*">
    </div>
  </div>
