
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-sidebar">
  <div class="sidebar-block-header">
    <div class="sidebar-item" matTooltip="{{ 'NEW_TASK' | translate }}">
      <button class="new-task-btn" (click)="openAddTaskDialog()">
        <mat-icon style="color: white;">event_available</mat-icon>
      </button>
      <div class="item-title" (click)="openAddTaskDialog()">
        {{ 'NEW_TASK' | translate }}
      </div>
      <button id="sidebar-collapse-button" *ngIf="isExpanded" mat-button (click)="toggleExpand()">
        <svg width="32px" height="32px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Icon/product/keyboard-arrow-left-new</title>
          <g id="Icon/product/keyboard-arrow-left-new" stroke="none" stroke-width="1" fill="#000000" fill-rule="evenodd">
              <path d="M15.6568542,7.29289322 C16.0473785,7.68341751 16.0473785,8.31658249 15.6568542,8.70710678 L11.8284271,12.5355339 L15.6568542,16.363961 C16.0473785,16.7544853 16.0473785,17.3876503 15.6568542,17.7781746 C15.26633,18.1686989 14.633165,18.1686989 14.2426407,17.7781746 L9,12.5355339 L14.2426407,7.29289322 C14.633165,6.90236893 15.26633,6.90236893 15.6568542,7.29289322 Z" id="Path" fill="#8B96A0"></path>
          </g>
      </svg>
      </button>
      <button id="sidebar-collapse-button" class="sidebar-collapse-btn" *ngIf="!isExpanded" mat-button (click)="toggleExpand()">
        <svg width="32px" height="32px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Icon/product/keyboard-arrow-right-new</title>
          <g id="Icon/product/keyboard-arrow-right-new" stroke="none" stroke-width="1" fill="#000000" fill-rule="evenodd">
              <path d="M9.29289322,7.29289322 C8.90236893,7.68341751 8.90236893,8.31658249 9.29289322,8.70710678 L13.1213203,12.5355339 L9.29289322,16.363961 C8.90236893,16.7544853 8.90236893,17.3876503 9.29289322,17.7781746 C9.68341751,18.1686989 10.3165825,18.1686989 10.7071068,17.7781746 L15.9497475,12.5355339 L10.7071068,7.29289322 C10.3165825,6.90236893 9.68341751,6.90236893 9.29289322,7.29289322 Z" id="Path" fill="#8B96A0"></path>
          </g>
      </svg>
      </button>
    </div>
  </div>

  <div class="task-sidebar-block">
    <div>
      <div id="sidebar-alltask-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_ALL_TASK)" (click)="navigateStatusRoute(taskConstants.ROUTE_ALL_TASK)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.ALL_TASK | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mat-icon>event_available</mat-icon>
            </div>
            <div class="item-title"> {{ taskConstants.ALL_TASK | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_ALL_TASK)">{{ getTotalCount(taskConstants.ROUTE_ALL_TASK) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-new-option">
        <div class="sidebar-item" [class.active] = "isSelectedFilter(taskConstants.ROUTE_NEW)" (click) = "navigateStatusRoute(taskConstants.ROUTE_NEW)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.NEW_TASK | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/panorama-fish-eye</title>
                <g id="Icon/product/panorama-fish-eye" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#8B96A0">
                        <path d="M10,0 C4.47,0 0,4.47 0,10 C0,15.53 4.47,20 10,20 C15.53,20 20,15.53 20,10 C20,4.47 15.53,0 10,0 L10,0 Z M10,18 C5.59,18 2,14.41 2,10 C2,5.59 5.59,2 10,2 C14.41,2 18,5.59 18,10 C18,14.41 14.41,18 10,18 L10,18 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>
            </div>
            <div class="item-title"> {{ taskConstants.NEW_TASK | translate }} </div>
            <div class ="rounded-rectangle" [class.active] = "isSelectedFilter(taskConstants.ROUTE_NEW)">{{ getTotalCount(taskConstants.ROUTE_NEW) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-open-option">
        <div class="sidebar-item" [class.active] = "isSelectedFilter(taskConstants.ROUTE_OPEN)" (click) = "navigateStatusRoute(taskConstants.ROUTE_OPEN)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.OPEN | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/timelapse</title>
                <g id="Icon/product/timelapse" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#8B96A0">
                        <path d="M14.24,5.76 C13.07,4.59 11.54,4 10,4 L10,10 L5.76,14.24 C8.1,16.58 11.9,16.58 14.25,14.24 C16.59,11.9 16.59,8.1 14.24,5.76 L14.24,5.76 Z M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M10,18 C5.58,18 2,14.42 2,10 C2,5.58 5.58,2 10,2 C14.42,2 18,5.58 18,10 C18,14.42 14.42,18 10,18 L10,18 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>
            </div>
            <div class="item-title"> {{ taskConstants.OPEN | translate }} </div>
            <div class ="rounded-rectangle" [class.active] = "isSelectedFilter(taskConstants.ROUTE_OPEN)">{{ getTotalCount(taskConstants.ROUTE_OPEN) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-completed-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_COMPLETED)" (click)="navigateStatusRoute(taskConstants.ROUTE_COMPLETED)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.COMPLETED | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/check-circle</title>
                <g id="Icon/product/check-circle" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#8B96A0">
                        <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 L10,0 Z M8,15 L3,10 L4.41,8.59 L8,12.17 L15.59,4.58 L17,6 L8,15 L8,15 Z" id="Shape"></path>
                    </g>
                </g>
              </svg>
            </div>
            <div class="item-title"> {{ taskConstants.COMPLETED | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_COMPLETED)">{{ getTotalCount(taskConstants.ROUTE_COMPLETED) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-assignedtome-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_ASSIGNEDTOME)" (click)="navigateStatusRoute(taskConstants.ROUTE_ASSIGNEDTOME)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.ASSIGNEDTOME | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/assignment-ind</title>
                <g id="Icon/product/assignment-ind" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-24px" transform="translate(3.000000, 1.000000)" fill="#8B96A0">
                        <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M9,6 C10.66,6 12,7.34 12,9 C12,10.66 10.66,12 9,12 C7.34,12 6,10.66 6,9 C6,7.34 7.34,6 9,6 L9,6 Z M15,18 L3,18 L3,16.6 C3,14.6 7,13.5 9,13.5 C11,13.5 15,14.6 15,16.6 L15,18 L15,18 Z" id="Shape"></path>
                    </g>
                </g>
              </svg>
            </div>
            <div class="item-title"> {{ taskConstants.ASSIGNEDTOME | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_ASSIGNEDTOME)">{{ getTotalCount(taskConstants.ROUTE_ASSIGNEDTOME) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-createdbyme-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_CREATEDBYME)" (click)="navigateStatusRoute(taskConstants.ROUTE_CREATEDBYME)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.CREATEDBYME | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/assignment-24px</title>
                <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#8B96A0">
                        <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>
            </div>
            <div class="item-title"> {{ taskConstants.CREATEDBYME | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_CREATEDBYME)">{{ getTotalCount(taskConstants.ROUTE_CREATEDBYME) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-todaydue-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_TODAY_DUE)" (click)="navigateStatusRoute(taskConstants.ROUTE_TODAY_DUE)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.TODAY_DUE | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mat-icon>access_time</mat-icon>
            </div>
            <div class="item-title"> {{ taskConstants.TODAY_DUE | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_TODAY_DUE)">{{ getTotalCount(taskConstants.ROUTE_TODAY_DUE) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-tomorrow-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_TOMORROW_DUE)" (click)="navigateStatusRoute(taskConstants.ROUTE_TOMORROW_DUE)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.TOMORROW_DUE | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <mat-icon>watch_later</mat-icon>
            </div>
            <div class="item-title"> {{ taskConstants.TOMORROW_DUE | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_TOMORROW_DUE)">{{ getTotalCount(taskConstants.ROUTE_TOMORROW_DUE) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-thisweek-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_THIS_WEEK_DUE)" (click)="navigateStatusRoute(taskConstants.ROUTE_THIS_WEEK_DUE)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.THIS_WEEK_DUE | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/date-new</title>
                <g id="Icon/product/date-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M15.5,3 C16.3284271,3 17,3.67157288 17,4.5 L17,5 L18,5 C19.5976809,5 20.9036609,6.24891996 20.9949073,7.82372721 L21,8 L21,18 C21,19.5976809 19.75108,20.9036609 18.1762728,20.9949073 L6,21 C4.40231912,21 3.09633912,19.75108 3.00509269,18.1762728 L3,18 L3,8 C3,6.40231912 4.24891996,5.09633912 5.82372721,5.00509269 L7,5.005 L7,4.5 C7,3.67157288 7.67157288,3 8.5,3 C9.32842712,3 10,3.67157288 10,4.5 L10,5.003 L14,5.002 L14,4.5 C14,3.67157288 14.6715729,3 15.5,3 Z M18,7 L6,7 C5.48716416,7 5.06449284,7.38604019 5.00672773,7.88337887 L5,8 L5,18 C5,18.5128358 5.38604019,18.9355072 5.88337887,18.9932723 L6,19 L18,19 C18.5128358,19 18.9355072,18.6139598 18.9932723,18.1166211 L19,18 L19,8 C19,7.44771525 18.5522847,7 18,7 Z M16.9804916,14 C17.5370911,14 17.9883041,14.451213 17.9883041,15.0078125 C17.9883041,15.564412 17.5370911,16.015625 16.9804916,16.015625 L7.01950841,16.015625 C6.46290893,16.015625 6.01169591,15.564412 6.01169591,15.0078125 C6.01169591,14.451213 6.46290893,14 7.01950841,14 L16.9804916,14 Z M7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 Z" id="Combined-Shape" fill="#8B96A0"></path>
                </g>
            </svg>
            </div>
            <div class="item-title"> {{ taskConstants.THIS_WEEK_DUE | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_THIS_WEEK_DUE)">{{ getTotalCount(taskConstants.ROUTE_THIS_WEEK_DUE) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-tasksiwatch-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_TASKS_I_WATCH)" (click)="navigateStatusRoute(taskConstants.ROUTE_TASKS_I_WATCH)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.TASKS_I_WATCH | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/visibility</title>
                <g id="Icon/product/visibility" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-24px" transform="translate(1.000000, 4.500000)" fill="#8B96A0">
                        <path d="M11,0 C16,0 20.27,3.11 22,7.5 C20.27,11.89 16,15 11,15 C6,15 1.73,11.89 0,7.5 C1.73,3.11 6,0 11,0 Z M11,2 C7.3276702,2 4.03570887,4.0392665 2.34847366,7.18104549 L2.185,7.499 L2.207812,7.54698098 C3.80585357,10.7580378 7.05081834,12.8886127 10.7102582,12.9957618 L11,13 C14.7772535,13 18.1520927,10.8425393 19.792188,7.54698098 L19.814,7.499 L19.792188,7.45301902 C18.1941464,4.24196222 14.9491817,2.11138727 11.2897418,2.00423816 L11,2 Z M11,3.5 C13.2085695,3.5 15,5.2914305 15,7.5 C15,9.7085695 13.2085695,11.5 11,11.5 C8.7914305,11.5 7,9.7085695 7,7.5 C7,5.2914305 8.7914305,3.5 11,3.5 Z M11,5.5 C9.896,5.5 9,6.396 9,7.5 C9,8.604 9.896,9.5 11,9.5 C12.104,9.5 13,8.604 13,7.5 C13,6.396 12.104,5.5 11,5.5 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>
            </div>
            <div class="item-title"> {{ taskConstants.TASKS_I_WATCH | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_TASKS_I_WATCH)">{{ getTotalCount(taskConstants.ROUTE_TASKS_I_WATCH) }}</div>
          </div>
        </div>
      </div>
      <div id="sidebar-overdue-option">
        <div class="sidebar-item" [class.active]="isSelectedFilter(taskConstants.ROUTE_MY_OVERDUE_TASKS)" (click)="navigateStatusRoute(taskConstants.ROUTE_MY_OVERDUE_TASKS)">
          <div class="sidebar-item-div" matTooltip="{{ taskConstants.MY_OVERDUE_TASKS | translate }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
            <div class="item-icon">
              <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>Icon/product/assignment-late</title>
                <g id="Icon/product/assignment-late" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Icon-24px" transform="translate(3.000000, 1.000000)" fill="#8B96A0">
                        <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M10,17 L8,17 L8,15 L10,15 L10,17 L10,17 Z M10,13 L8,13 L8,7 L10,7 L10,13 L10,13 Z M9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 L9,4 Z" id="Shape"></path>
                    </g>
                </g>
            </svg>
            </div>
            <div class="item-title"> {{ taskConstants.MY_OVERDUE_TASKS | translate }} </div>
            <div class="rounded-rectangle" [class.active]="isSelectedFilter(taskConstants.ROUTE_MY_OVERDUE_TASKS)">{{ getTotalCount(taskConstants.ROUTE_MY_OVERDUE_TASKS) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="separate-bar"></div>
    <div class="header-title-div" [class.collapsed]="!isExpanded" (click)="folderListExpanded ? !folderListExpanded = false: folderListExpanded = true">
      <div *ngIf="!isExpanded" [matMenuTriggerFor]="collapseListmenu" class="item-icon" matTooltip="{{ 'LISTS' | translate }}" matTooltipPosition="after">
        <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Icon/product/assignment-24px</title>
          <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#8B96A0">
                  <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
              </g>
          </g>
        </svg>
      </div>
      <div *ngIf="isExpanded" class="header-title" matTooltip="{{ 'LISTS' | translate }}" matTooltipPosition="above">{{ 'LISTS' | translate }}</div>
      <div class="icon-div">
        <mat-icon class="add-list-icon" (click)="$event.stopPropagation();openAddListDialog()">playlist_add</mat-icon>
        <mdl-icon *ngIf="!folderListExpanded">expand_more</mdl-icon>
        <mdl-icon *ngIf="folderListExpanded">expand_less</mdl-icon>
      </div>
    </div>

    <mat-menu class="collapseMenu" #collapseListmenu="matMenu" xPosition="after">
      <div class="header-menu-item" mat-menu-item>
        <span class="item-title header-menu-item">{{ 'LISTS' | translate }}</span>
        <mat-icon class="" (click)="$event.stopPropagation();openAddListDialog()">playlist_add</mat-icon>
      </div>
      <div class="separate-bar"></div>
      <div mat-menu-item *ngFor="let list of folderList" (click)="navigateListRoute(list.name)">
          <span class="item-title" matTooltip="{{ list.name }}" matTooltipPosition="above">{{ list.name }}</span>
          <div class="rounded-rectangle task-count">
            <span>{{ list.tasks_count }}</span>
          </div>
      </div>
    </mat-menu>

    <div *ngIf="folderListExpanded && isExpanded">
      <div *ngFor="let list of folderList" class="sidebar-item" [class.active] = "isSelectedFilter(list.name)" (click) = "navigateListRoute(list.name)">
        <div class="sidebar-item-div" matTooltip="{{ list.name }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
          <div class="item-icon">
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/assignment-24px</title>
              <g id="Icon/product/assignment-24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(2.000000, 1.000000)" fill="#8B96A0">
                      <path d="M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z" id="Shape"></path>
                  </g>
              </g>
          </svg>
          </div>
          <div class="item-title">{{ list.name }}</div>
          <div class="more-count-div">
            <div class="dropbox">
              <mdl-icon (click)="$event.stopPropagation();" [matMenuTriggerData]="{name: tag}" [matMenuTriggerFor]="folderMenu">more_vert</mdl-icon>
            </div>
            <mat-menu #folderMenu="matMenu" xPosition="before">
              <ng-template matMenuContent let-name="name">
                <div class="context-item" (click)="deleteList(list)">
                  <mdl-icon>delete</mdl-icon>
                  <span>{{ 'DELETE' | translate }}</span>
                </div>
                <div class="context-item" (click)="renameList(list)">
                  <mdl-icon>edit</mdl-icon>
                  <span>{{ 'RENAME' | translate }}</span>
                </div>
              </ng-template>
            </mat-menu>
            <div class="rounded-rectangle task-count" [class.active] = "isSelectedFilter(list.name)">{{ list.tasks_count }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="separate-bar"></div>
    <div class="header-title-div" [class.collapsed]="!isExpanded" (click)="tagListExpanded ? !tagListExpanded = false: tagListExpanded = true">
      <div *ngIf="!isExpanded" [matMenuTriggerFor]="collapseTagmenu" class="item-icon" matTooltip="{{ 'TAGS' | translate }}" matTooltipPosition="after">
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Icon/product/tag-new</title>
          <g id="Icon/product/tag-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path d="M11,2 C11.5,2 11.9259259,2.14814815 12.2777778,2.44444444 L12.40625,2.5625 L21.40625,11.5625 C21.78125,11.9375 21.96875,12.40625 21.96875,12.96875 C21.96875,13.46875 21.8206019,13.8946759 21.5243056,14.2465278 L21.40625,14.375 L14.375,21.40625 C14,21.78125 13.53125,21.96875 12.96875,21.96875 C12.46875,21.96875 12.0428241,21.8206019 11.6909722,21.5243056 L11.5625,21.40625 L2.5625,12.40625 C2.22916667,12.0729167 2.04398148,11.6655093 2.00694444,11.1840278 L2,11 L2,3.96875 C2,3.40625 2.1875,2.9375 2.5625,2.5625 C2.89583333,2.22916667 3.30324074,2.04398148 3.78472222,2.00694444 L3.96875,2 L11,2 Z M11.013,3.997 L11,4 L4,4 L4,11 L3.997,11.013 L12.954,19.97 L12.96875,19.96875 L12.982,19.97 L19.97,12.982 L19.96875,12.96875 L19.97,12.954 L11.013,3.997 Z M6.4921875,5.015625 C6.9140625,5.015625 7.265625,5.15625 7.546875,5.4375 C7.828125,5.71875 7.96875,6.0703125 7.96875,6.4921875 C7.96875,6.9140625 7.828125,7.265625 7.546875,7.546875 C7.265625,7.828125 6.90625,7.96875 6.46875,7.96875 C6.0625,7.96875 5.71875,7.828125 5.4375,7.546875 C5.15625,7.265625 5.015625,6.9140625 5.015625,6.4921875 C5.015625,6.0703125 5.15625,5.71875 5.4375,5.4375 C5.71875,5.15625 6.0703125,5.015625 6.4921875,5.015625 Z" id="Combined-Shape" fill="#8B96A0"></path>
          </g>
      </svg>
      </div>
      <div *ngIf="isExpanded" class="header-title" matTooltip="{{ 'TAGS' | translate }}" matTooltipPosition="above">{{ 'TAGS' | translate }}</div>
      <div class="icon-div">
        <mdl-icon *ngIf="!tagListExpanded">expand_more</mdl-icon>
        <mdl-icon *ngIf="tagListExpanded">expand_less</mdl-icon>
      </div>
    </div>

    <mat-menu class="collapseMenu" #collapseTagmenu="matMenu" xPosition="after">
        <div class="header-menu-item" mat-menu-item>
          <span class="item-title header-menu-item">{{ 'TAGS' | translate }}</span>
        </div>
        <div class="separate-bar"></div>
        <div mat-menu-item *ngFor="let tag of tagList" (click)="navigateTagRoute(tag.name)">
            <span class="item-title" matTooltip="{{ tag.name }}" matTooltipPosition="above">{{ tag.name }}</span>
            <div class="rounded-rectangle task-count">
              <span>{{ tag.count }}</span>
            </div>
        </div>
    </mat-menu>

    <div *ngIf="tagListExpanded && isExpanded">
      <div *ngFor="let tag of tagList" class="sidebar-item" [class.active]="isSelectedFilter(tag.name)" (click)="navigateTagRoute(tag.name)">
        <div class="sidebar-item-div" matTooltip="{{ tag.name }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
          <div class="item-icon">
            <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/tag-new</title>
              <g id="Icon/product/tag-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M11,2 C11.5,2 11.9259259,2.14814815 12.2777778,2.44444444 L12.40625,2.5625 L21.40625,11.5625 C21.78125,11.9375 21.96875,12.40625 21.96875,12.96875 C21.96875,13.46875 21.8206019,13.8946759 21.5243056,14.2465278 L21.40625,14.375 L14.375,21.40625 C14,21.78125 13.53125,21.96875 12.96875,21.96875 C12.46875,21.96875 12.0428241,21.8206019 11.6909722,21.5243056 L11.5625,21.40625 L2.5625,12.40625 C2.22916667,12.0729167 2.04398148,11.6655093 2.00694444,11.1840278 L2,11 L2,3.96875 C2,3.40625 2.1875,2.9375 2.5625,2.5625 C2.89583333,2.22916667 3.30324074,2.04398148 3.78472222,2.00694444 L3.96875,2 L11,2 Z M11.013,3.997 L11,4 L4,4 L4,11 L3.997,11.013 L12.954,19.97 L12.96875,19.96875 L12.982,19.97 L19.97,12.982 L19.96875,12.96875 L19.97,12.954 L11.013,3.997 Z M6.4921875,5.015625 C6.9140625,5.015625 7.265625,5.15625 7.546875,5.4375 C7.828125,5.71875 7.96875,6.0703125 7.96875,6.4921875 C7.96875,6.9140625 7.828125,7.265625 7.546875,7.546875 C7.265625,7.828125 6.90625,7.96875 6.46875,7.96875 C6.0625,7.96875 5.71875,7.828125 5.4375,7.546875 C5.15625,7.265625 5.015625,6.9140625 5.015625,6.4921875 C5.015625,6.0703125 5.15625,5.71875 5.4375,5.4375 C5.71875,5.15625 6.0703125,5.015625 6.4921875,5.015625 Z" id="Combined-Shape" fill="#8B96A0"></path>
              </g>
          </svg>
          </div>
          <div class="item-title">{{ tag.name }}</div>
          <div class="rounded-rectangle" [class.active]="isSelectedFilter(tag.name)">{{ tag.count }}</div>
        </div>
      </div>
    </div>
    <div class="separate-bar"></div>
    <div class="header-title-div" [class.collapsed]="!isExpanded" (click)="locationListExpanded ? !locationListExpanded = false: locationListExpanded = true">
      <div *ngIf="!isExpanded" [matMenuTriggerFor]="collapseLocationmenu" class="item-icon" matTooltip="{{ 'LOCATIONS' | translate }}" matTooltipPosition="after">
        <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>Icon/product/location-new</title>
          <g id="Icon/product/location-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#8B96A0">
                  <path d="M8,0 C12.4228571,0 16,3.443 16,7.7 C16,9.65461538 15.0835503,11.9242604 13.8710168,14.0717708 L13.5334511,14.6540989 C13.476122,14.7505394 13.4182977,14.8466454 13.3600364,14.942376 L13.0056914,15.5119246 C12.9459153,15.6059889 12.8858188,15.6995955 12.8254602,15.7927035 L12.4606281,16.3450387 L12.0923004,16.8834349 L11.7232736,17.4059213 L11.3563441,17.9105274 L10.9943086,18.3952825 L10.6399636,18.8582157 L10.2961056,19.2973566 L8.76209811,21.1029683 C8.40451496,21.5238635 7.7734336,21.575188 7.35253844,21.2176049 C7.31128664,21.1825583 7.2729485,21.1442201 7.23790189,21.1029683 L5.7038944,19.2973566 L5.184,18.6296 L4.82546017,18.1555095 L4.46062813,17.6605826 L4.09230041,17.14679 C4.03077651,17.0596952 3.96922349,16.9718965 3.90769959,16.8834349 L3.53937187,16.3450387 L3.17453983,15.7927035 L2.816,15.2284 C2.69784615,15.0385231 2.5812071,14.8469799 2.46654893,14.6540989 L2.12898316,14.0717708 C0.916449704,11.9242604 0,9.65461538 0,7.7 C0,3.443 3.57714286,0 8,0 Z M8,2 C4.66497074,2 2,4.56503434 2,7.7 C2,8.69322797 2.3458444,9.96053154 3.01409327,11.4203421 C3.64939915,12.8081876 4.54667863,14.3111997 5.63770505,15.8676171 C6.30819597,16.8241143 7.01937804,17.7553435 7.73858591,18.6369503 L8,18.952 L8.26141409,18.6369503 C8.80081999,17.9757452 9.33571138,17.2866275 9.85230757,16.5798717 L10.362295,15.8676171 C11.4533214,14.3111997 12.3506009,12.8081876 12.9859067,11.4203421 C13.6541556,9.96053154 14,8.69322797 14,7.7 C14,4.56503434 11.3350293,2 8,2 Z M8,5.5 C9.38,5.5 10.5,6.62 10.5,8 C10.5,9.38 9.38,10.5 8,10.5 C6.62,10.5 5.5,9.38 5.5,8 C5.5,6.62 6.62,5.5 8,5.5 Z" id="Combined-Shape"></path>
              </g>
          </g>
      </svg>
      </div>
      <div *ngIf="isExpanded" class="header-title" matTooltip="{{ 'LOCATIONS' | translate }}" matTooltipPosition="above">{{ 'LOCATIONS' | translate }}</div>
      <div class="icon-div">
        <mat-icon class="add-list-icon" (click)="$event.stopPropagation();openAddLocationDialog()">add_location</mat-icon>
        <mdl-icon *ngIf="!locationListExpanded">expand_more</mdl-icon>
        <mdl-icon *ngIf="locationListExpanded">expand_less</mdl-icon>
      </div>
    </div>

    <mat-menu class="collapseMenu" #collapseLocationmenu="matMenu" xPosition="after">
        <div class="header-menu-item" mat-menu-item>
          <span class="item-title header-menu-item">{{ 'LOCATIONS' | translate }}</span>
          <mat-icon (click)="$event.stopPropagation();openAddLocationDialog()">add_location</mat-icon>
        </div>
        <div class="separate-bar"></div>
        <div mat-menu-item *ngFor="let location of locationList" (click)="navigateLocationRoute(location.name)">
            <span class="item-title" matTooltip="{{ location.name }}" matTooltipPosition="above">{{ location.name }}</span>
            <div class="rounded-rectangle task-count">
              <span>{{ location.tasks_count }}</span>
            </div>
        </div>
    </mat-menu>

    <div *ngIf="locationListExpanded && isExpanded">
      <div *ngFor="let location of locationList" class="sidebar-item" [class.active] = "isSelectedFilter(location.name)" (click) = "navigateLocationRoute(location.name)">
        <div class="sidebar-item-div" matTooltip="{{ location.name }}" matTooltipPosition="{{ isExpanded ? 'above': 'after' }}">
          <div class="item-icon">
            <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Icon/product/location-new</title>
              <g id="Icon/product/location-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#8B96A0">
                      <path d="M8,0 C12.4228571,0 16,3.443 16,7.7 C16,9.65461538 15.0835503,11.9242604 13.8710168,14.0717708 L13.5334511,14.6540989 C13.476122,14.7505394 13.4182977,14.8466454 13.3600364,14.942376 L13.0056914,15.5119246 C12.9459153,15.6059889 12.8858188,15.6995955 12.8254602,15.7927035 L12.4606281,16.3450387 L12.0923004,16.8834349 L11.7232736,17.4059213 L11.3563441,17.9105274 L10.9943086,18.3952825 L10.6399636,18.8582157 L10.2961056,19.2973566 L8.76209811,21.1029683 C8.40451496,21.5238635 7.7734336,21.575188 7.35253844,21.2176049 C7.31128664,21.1825583 7.2729485,21.1442201 7.23790189,21.1029683 L5.7038944,19.2973566 L5.184,18.6296 L4.82546017,18.1555095 L4.46062813,17.6605826 L4.09230041,17.14679 C4.03077651,17.0596952 3.96922349,16.9718965 3.90769959,16.8834349 L3.53937187,16.3450387 L3.17453983,15.7927035 L2.816,15.2284 C2.69784615,15.0385231 2.5812071,14.8469799 2.46654893,14.6540989 L2.12898316,14.0717708 C0.916449704,11.9242604 0,9.65461538 0,7.7 C0,3.443 3.57714286,0 8,0 Z M8,2 C4.66497074,2 2,4.56503434 2,7.7 C2,8.69322797 2.3458444,9.96053154 3.01409327,11.4203421 C3.64939915,12.8081876 4.54667863,14.3111997 5.63770505,15.8676171 C6.30819597,16.8241143 7.01937804,17.7553435 7.73858591,18.6369503 L8,18.952 L8.26141409,18.6369503 C8.80081999,17.9757452 9.33571138,17.2866275 9.85230757,16.5798717 L10.362295,15.8676171 C11.4533214,14.3111997 12.3506009,12.8081876 12.9859067,11.4203421 C13.6541556,9.96053154 14,8.69322797 14,7.7 C14,4.56503434 11.3350293,2 8,2 Z M8,5.5 C9.38,5.5 10.5,6.62 10.5,8 C10.5,9.38 9.38,10.5 8,10.5 C6.62,10.5 5.5,9.38 5.5,8 C5.5,6.62 6.62,5.5 8,5.5 Z" id="Combined-Shape"></path>
                  </g>
              </g>
          </svg>
          </div>
          <div class="item-title">{{ location.name }}</div>
          <div class="more-count-div">
            <div class="dropbox">
              <mdl-icon (click)="$event.stopPropagation();" [matMenuTriggerData]="{name: tag}" [matMenuTriggerFor]="locationMenu">more_vert</mdl-icon>
            </div>
            <mat-menu #locationMenu="matMenu" xPosition="before">
              <ng-template matMenuContent let-name="name">
                <div class="context-item" (click)="deleteLocation(location)">
                  <mdl-icon>delete</mdl-icon>
                  <span>{{ 'DELETE' | translate }}</span>
                </div>
                <div class="context-item" (click)="renameLocation(location)">
                  <mdl-icon>edit</mdl-icon>
                  <span>{{ 'RENAME' | translate }}</span>
                </div>
              </ng-template>
            </mat-menu>
            <div class="rounded-rectangle task-count" [class.active] = "isSelectedFilter(location.name)">{{ location.tasks_count }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="vnctask-loading" *ngIf="isLoading">
  <div class="indicator">
    <vnc-spinner [size]="'medium'" *ngIf="true"></vnc-spinner>
  </div>
</div>
