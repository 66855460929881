
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { HostListener, Component, ViewChild, ViewChildren, QueryList, AfterViewInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Inject, ElementRef } from "@angular/core";
import { TaskOptionComponent } from "./task-options/task-option.component";
import { TaskComposeInputComponent } from "./task-compose-input/task-compose-input.component";
import { FileUpload, Project } from "../../models";
import { TaskService } from "../../task.service";
import { ConfigService } from "../../../common/providers/config.service";
import { TranslateService } from "@ngx-translate/core";
import { TaskRepository } from "../../repository/task.repository";
import { TasksConstants } from "../../shared/task-constacts";
import { ErrorType, SuccessType } from "../../shared/task-enum";
import { ErrorService } from "../../../common/providers/error-service";
import { SuccessService } from "../../../common/providers/success-service";
import { Store } from "@ngrx/store";
import { TasksRootState, getAuthUser, getProjectsList, getTasksIsLoading, getMemberList } from "../../store/index";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { AuthUser } from "../../models/user";
import { TaskConfirmDialogComponent } from "../task-dialogs/confirm-dialog.component";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { dialogType } from "../../models/dialog-type";
import { CommonUtil } from "../../../common/utils/common.utils";
import { getOnlineStatus } from "../../../reducers";
import { TaskAddTagsOptionComponent, TaskAddWatchersOptionComponent, TaskAddUserOptionComponent } from "./task-options";
import { takeWhile, take, filter, takeUntil, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { VncLibraryService } from "vnc-library";
import { UntypedFormControl, Validators } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";
import { DatePipe } from '@angular/common';

@Component({
  selector: "vp-vnctask-task-compose",
  templateUrl: "task-compose.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskComposeComponent implements AfterViewInit, OnDestroy {
  @ViewChildren(TaskOptionComponent) optionComponents: QueryList<TaskOptionComponent>;
  @ViewChild(TaskComposeInputComponent, { static: false }) inputComponent: TaskComposeInputComponent;
  @ViewChild(TaskAddTagsOptionComponent, { static: false }) tagComponent: TaskAddTagsOptionComponent;
  @ViewChild(TaskAddWatchersOptionComponent, { static: false }) watcherComponent: TaskAddWatchersOptionComponent;
  @ViewChild(TaskOptionComponent, { static: false }) optionComponent: TaskAddWatchersOptionComponent;

  @ViewChild("chatWindow", {static: false}) chatWindow: ElementRef;

  _optionComponents: TaskOptionComponent[] = [];
  activeComponent: TaskOptionComponent;
  selectedProject: Project;
  submitting: boolean = true;
  addTaskInProgress: boolean = false;
  configProject: any;
  defaultConfigProject: String;
  currentUser: AuthUser;
  isAlive = true;
  isDescOpen: boolean = false;
  dialogType = dialogType;
  projectList: Project[] = [];
  isOnline: boolean = false;
  pDialog: any;
  isLoading: boolean = false;
  isOnIOS: boolean = CommonUtil.isOnIOS();
  assignee: any;
  isMobileDevice = CommonUtil.isOnMobileDevice();
  openConfirmation: boolean = false;
  msgs:any;
  showDragDropSection: boolean = false;
  @ViewChild("chatDragDropShade", {static: false}) chatDragDropShade: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<TaskComposeComponent>,
    private vncLibaryService: VncLibraryService,
    private service: TaskService,
    private configService: ConfigService,
    private translate: TranslateService,
    private tasksRepo: TaskRepository,
    private errorService: ErrorService,
    private successService: SuccessService,
    private store: Store<TasksRootState>,
    private broadcaster: Broadcaster,
    private changerDetectorRef: ChangeDetectorRef,
    private messageTranslatorService: MessageTranslatorService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog,
    private datePipe: DatePipe,
    public el: ElementRef,
  ) {
    this.messageTranslatorService.translatedMessagesd$.subscribe(res => {
      this.msgs = res;
    });
    this.taskName = new UntypedFormControl("", [Validators.required]);
    this.taskChip = new UntypedFormControl(" ", [Validators.required]);

    if (this.data && this.data.assignee) {
      this.assignee = this.data.assignee;
      this.changerDetectorRef.markForCheck();
    }
    this.setupStore();
    this.handleTaskCreateSuccess();
    this.handleTaskCreateFail();

    this.broadcaster.on<any>("closeAllMdlDialogs").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.goBack();
      });

    this.broadcaster.on<any>("hideTaskComposeDialog").pipe(takeWhile(() => this.isAlive))
      .subscribe(presence => {
        this.goBack();
      });
  }
  private isAlive$ = new Subject<boolean>();

  ngAfterViewInit() {
    this.taskName.valueChanges.pipe(takeUntil(this.isAlive$), debounceTime(500)).subscribe(value => {
     console.log("taskName value", value)
     if (value !== null && value.length > 0 && (this.selectedProject || this.configProject)) {
      this.submitting = false;
      } else {
        let components = this._optionComponents.filter(component => component.getValue() );
        if (components.length > 0) {
          this.submitting = false;
        } else {
          this.submitting = true;
        }
      }

    });
    if ( this.data && (this.data.description !== undefined)) {
      let component = this.optionComponents.find(component => component.id === "add-description");
      this.inputComponent.setActiveComponent(component);
      component.content = this.data.description;
    }

    setTimeout(() => {
      this._optionComponents = this.optionComponents.toArray();

      this.store.select(getMemberList).pipe(filter(members => members.length > 0), take(1)).subscribe( members => {
        if (this.assignee) {
          let member = members.find( member =>  member.username === this.assignee);
          if (member) {
            let component = this.optionComponents.find(component => component.id === "add-user");
            this.inputComponent.setActiveComponent(component);
            component.onItemClick(member);
            if (this.inputComponent && this.inputComponent.composeInput) {
              this.inputComponent.composeInput.nativeElement.focus();
            }
          }
        }
      });
    });
  }

  handleTaskCreateSuccess() {
    this.successService.only(SuccessType.TaskCreated).pipe(takeWhile(() => this.isAlive)).subscribe(success => {
      this.dialogRef.close();
      this.submitting = false;
      if (success.taskId) {
        const id = success.taskId;
        const text = this.msgs.TASK_NEW_CREATED.replace("{{ UpdatedTaskId }}", `#${id.toString()}`);
        this.vncLibaryService.openSnackBar(text, "task-update","", "", 3000, "bottom", "left");
      }
      else {
        this.vncLibaryService.openSnackBar(success.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
      }
      this.tasksRepo.getTasksStats();
      this.tasksRepo.getFolderListWithCounters();
      this.tasksRepo.getTagsListWithCounters();
      this.tasksRepo.getLocationsWithCounters();

      this.addTaskInProgress = false;
      setTimeout(() => {
        this.changerDetectorRef.markForCheck();
      }, 100);
    });
  }

  handleTaskCreateFail() {
    this.errorService.only(ErrorType.TaskCreateFail).pipe(takeWhile(() => this.isAlive)).subscribe(error => {
      this.submitting = false;
      this.vncLibaryService.openSnackBar(error.messages, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.addTaskInProgress = false;
      setTimeout(() => {
        this.changerDetectorRef.markForCheck();
      }, 100);
    });
  }

  setupStore() {
    this.store.select(getProjectsList).pipe(takeWhile(() => this.isAlive)).subscribe(projectList => {
console.log("setupStore projectlist", getProjectsList)
            if (projectList.length > 0) {
        this.projectList = projectList;
        this.changerDetectorRef.markForCheck();

        this.configProject = projectList.find( project => project.identifier === localStorage.getItem("redmineDefaultProject"));

        if (!this.configProject) {
          console.error("[TaskComposeComponent][setupStore][getProjectsList] no default project returned from server, ask an administrator to fix it");
        }

        this.selectedProject = this.configProject;
        this.tasksRepo.getMemberList(this.selectedProject.id);
        this.changerDetectorRef.markForCheck();
      }
    });

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.currentUser = user;
      }
    });

    this.store.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getTasksIsLoading).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      console.log("[task-compose.component] isLoading: " + value);
      this.isLoading = value;
      setTimeout(() => {
        this.changerDetectorRef.markForCheck();
      }, 100);
    });
  }

  openDialog(id: string) {
    console.log("[task-compose] onStartDateClick", id);

    let component = this.optionComponents.find(component => component.id === id);
    if (!component.isValid() && !this.submitting) {
      return component.validate();
    }
    if (!component.getValue() && !this.submitting) {
      if (id === "add-description") {
        this.isDescOpen = true;
      } else {
        this.isDescOpen = false;
      }
      this.changerDetectorRef.markForCheck();
      this.inputComponent.setActiveComponent(component);
    }
  }

  goBack() {
    if(this.isMobileDevice) {
      if (this.inputComponent.getText().trim() === "" || this.activeComponent) {
        if (!this.activeComponent) return this.dialogRef.close();
        this.inputComponent.clearTriggerKey();
        return false;
      }
    } else {
      if (this.taskName.value.trim() === "") {
        if (!this.activeComponent) return this.dialogRef.close();
        return false;
      }
    }


    if (this.openConfirmation) return;
    this.openConfirmation = true;
    const dlg = this.matDialog.open(TaskConfirmDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-task-confirm-dialog",
      hasBackdrop: false,
      data: { type: this.dialogType.UNSAVE, message: this.messageTranslatorService.getMessage(TasksConstants.TASK_NOT_SAVED_MSG), header: this.messageTranslatorService.getMessage(TasksConstants.SAVE_TASK) }
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
      this.openConfirmation = false;
      if (!!res) {
        if(this.isMobileDevice) {
          this.addTask();
        } else {
          this.preparePayloadForCreateTask();
        }

      } else {
        if (!this.activeComponent) return this.dialogRef.close();
        this.inputComponent.clearTriggerKey();
      }
    });
  }

  @HostListener("document:keydown.esc", ["$event"])
  hide() {
    this.goBack();
  }

  onOptionClick(event) {
    this.isDescOpen = false;
    let component = this.inputComponent.getActiveComponent();
    this.inputComponent.insertTag(component.getValue());
  }

  onTagOptionClick(items) {
    let component = this.inputComponent.getActiveComponent();
    for (let i = 0; i < items.length; i++) {
      this.inputComponent.activeComponent = component;
      this.inputComponent.insertTag(items[i]);
    }
  }

  onWatcherOptionClick(items) {
    let component = this.inputComponent.getActiveComponent();
    for (let i = 0; i < items.length; i++) {
      this.inputComponent.activeComponent = component;
      this.inputComponent.insertTag(items[i]);
    }
  }

  onStartDateClick(startSelectedDate) {
    console.log("[task-compose] onStartDateClick", startSelectedDate);
    let component = this.inputComponent.getActiveComponent();
    this.inputComponent.insertTag(startSelectedDate);
  }

  onCompletionDateClick(composeTaskCompleteDate) {
    let component = this.inputComponent.getActiveComponent();
    this.inputComponent.insertTag(composeTaskCompleteDate);
  }

  onValueChange(data: any) {
    console.log("[task-compose] onValueChange", data);
    let existProject = false;
    let subject: string = this.inputComponent.getText();
    if (data.id !== undefined && data.id === "link-to-project") {
      let component = this._optionComponents.find(component => component.id === data.id);
      this.selectedProject = component.getValue();
      if (!this.selectedProject) {
        this.selectedProject = this.configProject;
      }
      if (!this.selectedProject && !this.configProject) {
        this.submitting = true;
      }
    } else if (data.id !== undefined && data.id === "vp-vnctask-compose-input") {
      if (subject !== null && subject.length > 0 && (this.selectedProject || this.configProject)) {
        this.submitting = false;
      } else {
        let components = this._optionComponents.filter(component => component.getValue() );
        if (components.length > 0) {
          this.submitting = false;
        } else {
          this.submitting = true;
        }
      }
    }
    this.changerDetectorRef.markForCheck();
  }

  onActiveComponentChange(component: TaskOptionComponent) {
    this.activeComponent = component;
    if (this.activeComponent && this.activeComponent.id === "add-description") {
      this.isDescOpen = true;
    } else {
      this.isDescOpen = false;
    }
  }

  addTask() {
    this.addTaskInProgress = true;
    if (this.inputComponent.getText().trim() === "") {
      let components = this._optionComponents.filter(component => component.getValue() );
      if (components.length === 0) {
        this.addTaskInProgress = false;
        this.goBack();
        return;
      }
    }
    if (this.inputComponent.getText().length > 255 ) {
      this.submitting = false;
      this.tasksRepo.subjectTooLong();
      this.addTaskInProgress = false;
      return;
    }

    let subjectFirstCharacter = this.inputComponent.getText().trim().charAt(0);
    if (subjectFirstCharacter === "~" || subjectFirstCharacter === "^" || subjectFirstCharacter === "*" || subjectFirstCharacter === "=" || subjectFirstCharacter === "+" || subjectFirstCharacter === "!") {
      this.submitting = false;
      this.tasksRepo.subjectNotAllowed();
      this.addTaskInProgress = false;
      return;
    }

    let payload: any = { subject: this.inputComponent.getText().trim() };

    this._optionComponents.forEach(component => {
      Object.assign(payload, component.getPayload());
    });

    // validate estimated_hours
    if (payload.estimated_hours) {
      const estimatedH = payload.estimated_hours.split(":")[0];
      const estimatedM = payload.estimated_hours.split(":")[1];
      if ((estimatedH > 8) || (estimatedH >= 8 && estimatedM > 0)){
        this.submitting = false;
        this.tasksRepo.errorEstimateIsInvalid();
        this.addTaskInProgress = false;
        return;
      }
      if (estimatedM > 60) {
        this.submitting = false;
        this.tasksRepo.errorEstimateMinuteIsInvalid();
        this.addTaskInProgress = false;
        return;
      }
    }

    // validate due_date
    if (payload.due_date && payload.start_date) {
      const sd = new Date(payload.start_date);
      const dd = new Date(payload.due_date);
      if (dd < sd) {
        this.submitting = false;
        this.tasksRepo.errorDueDateInvalid();
        this.addTaskInProgress = false;
        return;
      }
    }

    // set default project
    if (payload.project_id === undefined || payload.project_id === null) {
      Object.assign(payload, { "project_id": this.configProject.id, "project_name": this.configProject.name });
    }

    if (!payload.assigned_to_id && !payload.invitee_email) {
      if (this.currentUser) {
        Object.assign(payload, { "assigned_to_id": this.currentUser.id });
        Object.assign(payload, { "assigned_to_name": this.currentUser.fullname });
      }
    }

    Object.assign(payload, { "author_id": this.currentUser.id });
    Object.assign(payload, { "author_name": this.currentUser.fullname });

    console.log("[task-compose] add task", payload);

    if (this.isOnline || CommonUtil.isSQLSupported()) {
      this.tasksRepo.addNewTask(payload);
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      this.addTaskInProgress = false;
    }
  }

  removeTag(tagName) {
    console.log("[TaskComposeComponent][removeTag] tagName", tagName);

    this.tagComponent.removeTag(tagName);
  }

  removeWatcher(userName) {
    console.log("[TaskComposeComponent][removeWatcher] userName", userName);

    this.watcherComponent.removeWatcher(userName);
  }

  ngOnDestroy(): void {
    this.isAlive = false;

    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.isAlive$.unsubscribe();
  }

  taskName: UntypedFormControl;
  taskChip: UntypedFormControl;


  selectedData:any = {
    startDate: {
      date: "",
      name: "",
    },
    dueDate: {
      date: "",
      name: "",
    },
    priority: {
      id: "",
      name: ""
    },
    user: {
      id: "",
      name: ""
    },
    repeat:{
      id: "",
      name: ""
    },
    estimate:{
      value: "",
      name: "",
    },
    list: {
      id: "",
      name: ""
    },
    watchers: [],
    tags: [],
    location: {
      id: "",
      name: ""
    },
   description: {
      value: "",
      name: ""
   },
   project: {
    value: "",
    name: ""
 }
  }

  allDataTag:any = [];

  activatedData :any = {
    startDate: false,
    dueDate: false,
    priority: false,
    user: false,
    repeat: false,
    estimate: false,
    listItem:false,
    location: false,
    tags:false,
    description:false,
    watcher:false,
    project: false,
  }

  payloadData() {
    let payload: any = { subject: this.inputComponent.getText().trim() };
    console.log("payloadData payload", payload);
  }
  onTaskNameChange() {
  }

  selectedStartDate(event) {
      console.log("selectedStartDate payload", event);
      this.selectedData.startDate.date = "";
      this.selectedData.startDate.name = "";
      if (event.date) {
        const originalDate = new Date(event.date);
        const sortedFormat = this.datePipe.transform(originalDate, 'yyyy-MM-dd');
        console.log("selectedStartDate -", sortedFormat);
        this.selectedData.startDate.date = sortedFormat;

        if(event.name === 'find') {
          this.selectedData.startDate.name = "~" + this.datePipe.transform(originalDate, 'MMM dd');
        } else if(event.name === "No Start Date"){
          this.selectedData.startDate.name = "~No Start Date"
        } else{
          this.selectedData.startDate.name = "~" + event.name;
        }
      } else if (event.name === "No Start Date"){
        this.selectedData.startDate.name = "~No Start Date"
      }
      this.allDataTag.push(this.selectedData.startDate);
      console.log("selectedStartDate payload", this.allDataTag);
  }

  selectedDueDate(event) {
    console.log("selectedStartDate payload", event);
    this.selectedData.dueDate.date = "";
    this.selectedData.dueDate.name = "";
    if (event.date) {
      const originalDate = new Date(event.date);
      const sortedFormat = this.datePipe.transform(originalDate, 'yyyy-MM-dd');
      this.selectedData.dueDate.date = sortedFormat;
      if (event.name === 'find') {
        this.selectedData.dueDate.name = "^" + this.datePipe.transform(originalDate, 'MMM dd');
      } else if(event.name === "No Due Date"){
        this.selectedData.dueDate.name = "^No Due Date"
      } else {
        this.selectedData.dueDate.name = "^" + event.name;
      }
    } if(event.name === "No Due Date"){
      this.selectedData.dueDate.name = "^No Due Date"
    }
    this.allDataTag.push(this.selectedData.dueDate);
    console.log("selected dueDate payload", this.selectedData);
}



  resetActivate() {
    this.activatedData = {
      startDate: false,
      dueDate: false,
      priority: false,
      user:false,
      estimate:false,
      listItem:false,
      location:false,
      tags:false,
      description:false,
      watcher:false,
      project:false
    }
  }

  selectActivate(event, type) {
    this.resetActivate();
    console.log("selectActivate payload", event, type);
    this.activatedData[type] = event;
    console.log("selectActivate payload", this.activatedData);
  }

  removeData(item){
    console.log("removeData payload", item);
    let initial = item.name[0];
    console.log("removeData payload initial", initial);
    if(initial === "~") {
      this.selectedData.startDate.date = "";
      this.selectedData.startDate.name = "";
    } else if(initial === "^") {
      this.selectedData.dueDate.date = "";
      this.selectedData.dueDate.name = "";
    }else if(initial === "!") {
      this.selectedData.priority.id = "";
      this.selectedData.priority.name = "";
    }else if(initial === "+") {
      this.selectedData.user.id = "";
      this.selectedData.user.name = "";
    } else if(initial === "*") {
      this.selectedData.repeat.id = "";
      this.selectedData.repeat.name = "";
    } else if(initial === '"') {
      this.selectedData.estimate.value = "";
      this.selectedData.estimate.name = "";
    } else if(initial === "$") {
      this.selectedData.list.id = "";
      this.selectedData.list.name = "";
    } else if(initial === "@") {
      this.selectedData.location.id = "";
      this.selectedData.location.name = "";
    } else if(initial === "%") {
      this.selectedData.description.value = "";
      this.selectedData.description.name = "";
    } else if(initial === "&") {
      // this.selectedData.watchers = [];
      this.service.removeWatcherSubject.next(item);
    } else if(initial === "=") {
      this.selectedData.project.id = "";
      this.selectedData.project.name = "";
    } else if(initial === ":") {
      this.removeArray(item.data);
    }
    this.allDataTag = this.allDataTag.filter((data) => data.name !== item.name);
  }

  removeArray(object) {
    this.uploadData = this.uploadData.filter(obj => obj.token !== object.token);
  }

  selectedPriority(event){
    this.selectedData.priority.id = event.id;
    this.selectedData.priority.name = event.name;
    this.allDataTag.push({id: event.id, name: "!"+event.name});
  }

  selectedProjectData(event) {
    this.selectedData.project.id = event.id;
    this.selectedData.project.name = event.name;
    this.allDataTag.push({id: event.id, name: "=" + event.name});
  }

  selectedUser(event){
    this.selectedData.user.id = event.id;
    this.selectedData.user.name = event.name;
    this.allDataTag.push({id: event.id, name: "+"+event.name});
  }

  selectedList(event){
    this.selectedData.list.id = event.id;
    this.selectedData.list.name = event.name;
    this.allDataTag.push({id: event.id, name: "$"+event.name});
  }

  selectedDescription(event){
    this.selectedData.description.value = event.value;
    this.selectedData.description.name = event.name;
    this.allDataTag.push({id: event.value, name: "%"+ event.name});
  }

  selectedTags(event){
    event.map((data) => {
      this.selectedData.tags.push(data);
      this.allDataTag.push({id:data.id, name: "#"+data.name});
    });
  }

  selectedLocation(event){
    this.selectedData.location.id = event.id;
    this.selectedData.location.name = event.name;
    this.allDataTag.push({id: event.id, name: "@"+event.name});
  }

  selectedRepeat(event){
    this.selectedData.repeat.id = event.value;
    this.selectedData.repeat.name = event.name;
    this.allDataTag.push({id: event.id, name: "*"+event.name});
  }

  selectedEstimate(event){
    this.selectedData.estimate.value = event.value;
    this.selectedData.estimate.name = event.name;
    this.allDataTag.push({id: event.id, name: `"` + event.name});
  }

  selectedWatcher(event){
    event.map((data) => {
      this.selectedData.watchers.push(data);
      this.allDataTag.push({data: data, name: "&"+data.name});
    });
  }

  addUploadData(uploadDataFile) {
    this.allDataTag.push({data: uploadDataFile, name: ":"+uploadDataFile.filename});
  }

  payloadForCreateTask :any =  {};

  preparePayloadForCreateTask() {
    console.log("preparePayloadForCreateTask payload", this.selectedData);
    this.addTaskInProgress = true;
    this.payloadForCreateTask = {
      subject: this.taskName.value.trim(),
    }
    if(this.selectedData.startDate !== ""){
      this.payloadForCreateTask["start_date"] = this.selectedData.startDate.date;
    }
    if(this.selectedData.dueDate !== ""){
      this.payloadForCreateTask["due_date"] = this.selectedData.dueDate.date;
    }
    if(this.selectedData.priority.id !== ""){
      this.payloadForCreateTask["priority_id"] = this.selectedData.priority.id;
      this.payloadForCreateTask["priority_name"] = this.selectedData.priority.name;
    }

    if(this.selectedData.project.id !== ""){
      this.payloadForCreateTask["project_id"] = this.selectedData.project.id;
    }

    if (this.selectedData.user.id !== "") {
      this.payloadForCreateTask["assigned_to_id"] = this.selectedData.user.id;
      this.payloadForCreateTask["assigned_to_name"] = this.selectedData.user.name;
    } else {
      this.payloadForCreateTask["assigned_to_id"] = this.currentUser.id;
      this.payloadForCreateTask["assigned_to_name"] = this.currentUser.fullname;
    }

    if(this.selectedData.list.id !== ""){
      this.payloadForCreateTask["list_id"] = this.selectedData.list.id;
      this.payloadForCreateTask["list_name"] = this.selectedData.list.name;
    }
    if(this.selectedData.repeat.id !== ""){
      this.payloadForCreateTask["repeat_id"] = this.selectedData.repeat.id;
      this.payloadForCreateTask["repeat_name"] = this.selectedData.repeat.name;
    }

    if(this.selectedData.estimate.value !== ""){
      this.payloadForCreateTask["estimated_hours"] = this.selectedData.estimate.value;
    }

    if(this.selectedData.tags.length > 0){
      this.payloadForCreateTask["tags"] = this.selectedData.tags
    }

    if(this.selectedData.location.id !== ""){
      this.payloadForCreateTask["location_id"] = this.selectedData.location.id;
      this.payloadForCreateTask["location_name"] = this.selectedData.location.name;
    }
    if(this.selectedData.description.value !== ""){
      this.payloadForCreateTask["description"] = this.selectedData.description.value;
    }

    if (this.selectedData.watchers.length > 0) {
      this.payloadForCreateTask["watchers"] = this.selectedData.watchers;
    }

    if (this.uploadData.length > 0) {
      this.payloadForCreateTask["uploads"] = [];
      this.uploadData.map(upload=>{
        this.payloadForCreateTask.uploads.push({token: upload.token, filename: upload.filename, content_type: upload.content_type});
      })
    }

    this.payloadForCreateTask["author_id"]=this.currentUser.id;
    this.payloadForCreateTask["author_name"]=this.currentUser.fullname;

    this.payloadForCreateTask["project_id"]= this.configProject.id;
    this.payloadForCreateTask["project_name"]= this.configProject.name;

    console.log("preparePayloadForCreateTask payload", this.payloadForCreateTask);
    if (this.isOnline || CommonUtil.isSQLSupported()) {
      this.tasksRepo.addNewTask(this.payloadForCreateTask);
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      this.addTaskInProgress = false;
    }
  }

  checkIfAnyActivate(){
    let activate = false;
    if(this.activatedData.startDate || this.activatedData.dueDate || this.activatedData.priority || this.activatedData.user || this.activatedData.estimate || this.activatedData.listItem || this.activatedData.repeat  || this.activatedData.location || this.activatedData.tags || this.activatedData.description || this.activatedData.watcher){
      activate = true;
    }

    return activate;
  }

  cancelClick(){
    if(this.checkIfAnyActivate()){
      this.service.cancelActivationOfTags.next(true);
    } else {
      this.goBack();
    }
  }


  files: any[] = [];
  showOndragDiv:boolean = false;
  hideTimer: NodeJS.Timeout;
  showDragDrop() {
    if (this.hideTimer) {
      clearTimeout(this.hideTimer);
    }
  }
  hideDragDrop() {
    this.hideTimer = setTimeout(() => {
      this.showOndragDiv = false;
      this.changerDetectorRef.markForCheck();
      console.log("dragDropFunctionality DragDrop", this.showOndragDiv)
    }, 100);
  }

  onDragOver(event: DragEvent) {
    console.log("dragDropFunctionality over", event);
    this.showOndragDiv = true;
    this.showDragDrop();
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent) {
    console.log("dragDropFunctionality onDragLeave", event);
    this.hideDragDrop();

    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    console.log("dragDropFunctionality onDrop", event);
    this.showOndragDiv = false;
    this.hideDragDrop();
    this.fileRead(event,true)
    event.preventDefault();
    event.stopPropagation();

  }

  fileRead($event, drop:boolean = false) {
    let file: any;
    if(drop){
      file = $event.dataTransfer.files[0];
    } else {
      file = $event.target.files[0];
    }
    this.fileUploadListener(file);
  }
  fileUploadListener(file) {
    let myReader: FileReader = new FileReader();
    let self = this;
    if ( file.size > TasksConstants.FILE_UPLOAD_LIMIT) {
      this.broadcaster.broadcast("OnFileLimitExcededError");
      this.changerDetectorRef.markForCheck();
    } else {
      myReader.onloadend = (loadEvent: any) => {
        let upload: FileUpload = {
          filename: file.name,
          content_type: file.type,
          content: myReader.result
        };
        this.uploadAttachmentForToken(upload);
        this.changerDetectorRef.markForCheck();
      };
      myReader.readAsArrayBuffer(file);
    }
  }


  uploadAttachmentForToken(upload) {
    if(!this.taskName.value || this.submitting) return;
    if (this.isOnline) {
      if (upload) {
        this.isLoading = true;
        this.changerDetectorRef.markForCheck();
        this.service.uploadFile(upload.content).subscribe( res => {
          let uploadData = {
            filename: upload.filename,
            token: res.upload.token,
            content_type: upload.content_type
          }
          this.uploadData.push(uploadData);
          console.log("uploadAttachmentForToken res", res);
          upload.token = res.upload.token;
          this.addUploadData(uploadData);
          this.changerDetectorRef.markForCheck();
        }, err => {
          this.changerDetectorRef.markForCheck();
          this.errorService.emit({ id: ErrorType.GenericMessage, messages: err });
        });
      }
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  uploadData = [];
}
