
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, HostListener, Input, ViewChild, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../common/providers/auth.service";
import { ConfigService } from "../../../common/providers/config.service";
import { HeaderService, HeaderEvent } from "../../services/header.service";
import { Subject } from "rxjs";
import { ResponsiveService } from "../../../common/providers/responsive.service";
import { Store } from "@ngrx/store";
import { TasksRootState, getAuthUser, getIsSearchMode, SearchModeToggle, getSelectedFilterType, getTasksIsLoading, SetDetailView } from "../../store/index";
import { environment } from "../../../../environments/environment";
import { Environment } from "../../../../environments/environment.interface";
import { CommonUtil } from "../../../common/utils/common.utils";
import { TaskService } from "../../task.service";
import { TasksConstants } from "../../shared/task-constacts";
import { MessageTranslatorService } from "../../services/message-translator-service";
import { ErrorService } from "../../../common/providers/error-service";
import { ErrorType } from "../../shared/task-enum";
import { Broadcaster } from "../../../common/providers/broadcaster.service";
import { Logout } from "../../../actions/app";
import { AdvanceSearchRequest, TimeRangeType, DueDateTimeRangeType } from "../../models";
import { SearchRepository } from "../../repository/search.repository";
import { MdlDialogService } from "@angular-mdl/core";
import { TaskAboutusDialogComponent } from "../task-settings/task-aboutus-dialog/task-aboutus-dialog.component";
import { TaskChangelogDialogComponent } from "../task-settings/task-changelog-dialog/task-changelog-dialog.component";
import { TaskGeneralSettingsDialogComponent } from "../task-settings/task-general-settings-dialog/task-general-settings.component";
import { TaskServiceDeskDialogComponent } from "../task-settings/task-service-desk-dialog/task-service-desk-dialog.component";
import { TaskHelpAndFaqDialogComponent } from "../task-settings/task-help-faq-dialog/task-help-faq-dialog.component";
import { TaskLoginProfileDialogComponent } from "../task-login-user-profile/task-login-user-profile.component";
import { TaskRepository } from "../../repository/task.repository";
import { getOnlineStatus } from "../../../reducers";
import { TaskLegalNoticeDialogComponent } from "../task-settings/task-legal-notice-dialog/task-legal-notice-dialog.component";
import { getIsDetailView } from "../../store/selectors";
import { ResetSelectedTaskId, SetSelectAll } from "../../store/actions";
import { UsersManagementComponent } from "../sidebar/users-management.component";
import { ArchiveUsersComponent } from "../sidebar/archive-users.components";
import { SaveSearchDialogComponent } from "../save-search-dialog/save-search-dialog.component";
import { takeWhile, take, takeUntil } from "rxjs/operators";
import { ChangePasswordFormComponent } from "../change-password/change-password-form/change-password-form.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "vp-header",
  templateUrl: "./header.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HeaderComponent implements OnInit, OnDestroy {
  environment: Environment = environment;
  showMenu: boolean = false;
  user: any = {};
  userImage: string = "";
  switchApp: boolean = false;
  appLogo: string = "";
  search: string = "";
  showSettings: boolean = false;
  currentUser: any = {};
  breadcrumbs: any[] = [];
  @Input() url: string;
  showAdvancedSearchPopover: boolean = false;
  showAppSwitcher: boolean = true;
  destroy$ = new Subject();
  private isAlive$ = new Subject<boolean>();
  isAlive = true;
  screen: string = this.responsiveService.getScreen();
  advanceSearch: AdvanceSearchRequest;
  keyword: string;
  selectedFilterType: string;
  taskConstants = TasksConstants;
  isMobileDevice = CommonUtil.isOnMobileDevice();
  isLoading = false;
  isSearchMode: boolean = false;
  isOnline: boolean = false;
  isDetailView: boolean = false;
  isCordovaOrElectron = environment.isCordova || environment.isElectron;

  ActiveSearchBar: boolean = false;
  isFocused: boolean = false;
  storedLanguage: string;
  constructor(
    private router: Router,
    private activated: ActivatedRoute,
    private config: ConfigService,
    private authService: AuthService,
    private taskService: TaskService,
    private responsiveService: ResponsiveService,
    private headerService: HeaderService,
    private store: Store<TasksRootState>,
    private searchRepo: SearchRepository,
    private taskRepo: TaskRepository,
    private errorService: ErrorService,
    private broadcaster: Broadcaster,
    private messageTranslatorService: MessageTranslatorService,
    private changerDetectorRef: ChangeDetectorRef,
    private dialogService: MdlDialogService,
    private matDialog: MatDialog ) {
    this.storedLanguage = localStorage.getItem(TasksConstants.TASK_LANGUAGE);
    this.showAppSwitcher = (this.config.get("appsEnabled") || [])
      .filter(app => app.enabled).length > 1;

    this.store.select(getAuthUser).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.currentUser = user;
        console.log("currentUser user", user);
        this.changerDetectorRef.markForCheck();
      }
    });
  }

  ngOnInit() {
    this.searchRepo.selectAdvanceSearchRequest().pipe(takeUntil(this.isAlive$)).subscribe((request) => {
      this.advanceSearch = request;
      setTimeout(() => {
        this.changerDetectorRef.markForCheck();
      }, 0);
    });

    this.searchRepo.getSearchKeyword().pipe(takeUntil(this.isAlive$)).subscribe((request) => {
      this.keyword = request;
      setTimeout(() => {
        this.changerDetectorRef.markForCheck();
      }, 0);
    });

    this.store.select(getOnlineStatus).pipe(takeUntil(this.isAlive$)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      this.changerDetectorRef.markForCheck();
    });

    this.store.select(getIsDetailView).pipe(takeWhile(() => this.isAlive)).subscribe( value => {
      this.isDetailView = value;
      this.changerDetectorRef.markForCheck();
    });

    this.headerService
      .searchText$
      .pipe(takeUntil(this.destroy$))
      .subscribe((text: string) => {
        this.search = text;
        this.changerDetectorRef.markForCheck();
      });

    this.responsiveService.screen$
      .pipe(takeUntil(this.destroy$))
      .subscribe(screen => {
        this.screen = screen;
        this.changerDetectorRef.markForCheck();
      });

    this.headerService.getSearchEmitter()
      .subscribe((data: HeaderEvent) => {
        if (data.action === HeaderEvent.ENTER) {
          if (data.text) {
            if (this.isOnline || CommonUtil.isSQLSupported()) {
              this.searchRepo.saveSearchKeyword(data.text);
              let query = this.searchRepo.getSearchQuery(data.text, this.advanceSearch);
              this.taskRepo.setSearchParams(query);

              // if already in search mode - then just show tasks
              if (this.isSearchMode) {
                if (this.isDetailView) {
                  this.store.dispatch(new SetSelectAll(false));
                  this.store.dispatch(new ResetSelectedTaskId());
                  this.store.dispatch(new SetDetailView(false));
                  this.router.navigate(["./", TasksConstants.ROUTE_SEARCH], { relativeTo: this.activated });
                }
                this.taskRepo.getTasks();
                this.taskRepo.getTasksStats();
              } else {
                this.router.navigate(["/task", TasksConstants.ROUTE_SEARCH], { queryParams: {} });
              }
            } else {
              this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
            }
          } else {
            this.errorService.emit({ id: ErrorType.GenericMessage, messages: this.messageTranslatorService.getMessage(TasksConstants.SEARCH_STRING_ERR_MSG) });
          }
        }
      });

    this.store.select(getIsSearchMode).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      if (!value) {
        this.search = "";
      }
      this.isSearchMode = value;
      this.changerDetectorRef.markForCheck();
    });

    this.store
      .select(getSelectedFilterType)
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(value => {
        this.selectedFilterType = value;
        this.changerDetectorRef.markForCheck();
      });

    this.store.select(getTasksIsLoading).pipe(takeWhile(() => this.isAlive)).subscribe((value) => {
      this.isLoading = value;
      this.changerDetectorRef.markForCheck();
    });
  }

  showDropDown($event, name: string): void {
    $event.isSettings = true;
    this[name] = !this[name];
    this.changerDetectorRef.markForCheck();
  }

  logout(): void {
    if (environment.isCordova && typeof window.FirebasePlugin !== "undefined") {
      window.FirebasePlugin.getToken(token => {
        this.taskService.removeFirebaseToken(token)
          .pipe(take(1))
          .subscribe(res => {
            console.log("[removeFirebaseToken]", token, res);
            window.FirebasePlugin.unregister();
            this.processLogout();
          }, err => {
            console.error("[removeFirebaseToken - error]", err);
            this.processLogout();
          });
      });

      window.FirebasePlugin.clearAllNotifications(() => {
        console.log("[FirebasePlugin clearAllNotifications] success");
      }, error => {
        console.log("[FirebasePlugin clearAllNotifications] error", error);
      });
    } else {
      this.processLogout();
    }
  }

  private processLogout(): void {
    console.log("[header.component] logout");
    this.taskRepo.logout();
  }

  searchTextUpdated(key: number): void {
    this.headerService.emit(key, this.search);
  }

  navigate(path: string[]): void {
    this.router.navigate(path);
  }

  emitAction(data: { event: any, action: string }) {
    data.event.stopPropagation();
    // this.headerService.emitAction(data.action);
    switch (data.action) {
      case HeaderService.ACTIONS.TASK.TASK_SEARCH:
        this.headerService.showSearchDialog$.next(true);
        break;
    }
    this.showSettings = false;
    this.changerDetectorRef.markForCheck();
  }

  switchApplication(event): void {
    if (event && event.switch) {
      this.switchApp = !this.switchApp;
    } else {
      this.switchApp = false;
    }
    this.changerDetectorRef.markForCheck();
  }

  @HostListener("document:click", ["$event"])
  onClickOutside($event): void {
    if (!($event.isSettings)) {
      this.showSettings = false;
      this.changerDetectorRef.markForCheck();
    }
  }

  toggleDrawer(): void {
    this.headerService.toggleDrawer();
  }

  hideSettings(): void {
    this.showSettings = false;
    this.changerDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    this.isAlive = false;
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.headerService.destroy();
  }

  showAdvancedTaskFilters() {
    if (this.isOnline) {
      this.headerService.showAdvancedTaskFilters$.next(true);
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  logoUrl() {
    return CommonUtil.getFullUrl("/assets/img/logo.png");
  }

  openAboutDialog(): void {
    const gdlg = this.matDialog.open(TaskAboutusDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskaboutdialog",
    });
  }

  openChangeLogDialog(): void {
    const gdlg = this.matDialog.open(TaskChangelogDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskchangelogdialog",
    });
  }

  openHelpFaqDialog(): void {
    const gdlg = this.matDialog.open(TaskHelpAndFaqDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskhelpfaqdialog",
    });
  }

  openServiceDeskDialog(): void {
    const aDialog = this.matDialog.open(TaskServiceDeskDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskservicedialog",
    });
  }

  openGeneralSettingDialog(): void {
    const gdlg = this.matDialog.open(TaskGeneralSettingsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctaskgeneralsettingsdialog",
    });
  }

  openProfileDialog() {
    const gdlg = this.matDialog.open(TaskLoginProfileDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnc_task_login_profile_dialog",
    });
  }

  openLegalNoticeDialog() {
    const gdlg = this.matDialog.open(TaskLegalNoticeDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vnctasksettingsdialog",
    });
  }

  openAppFaq() {
    if (this.storedLanguage === "de") CommonUtil.openUrl("https://de.docs.vnc.biz/VNCtask/faq/");
    else CommonUtil.openUrl("https://en.docs.vnc.biz/VNCtask/faq/");
  }

  openUserManual() {
    if (this.storedLanguage === "de") CommonUtil.openUrl("https://de.docs.vnc.biz/VNCtask/webclient/");
    else CommonUtil.openUrl("https://en.docs.vnc.biz/VNCtask/taskusermanualdesktop/");
  }

  openTermOfUse() {
    if (this.storedLanguage === "de") CommonUtil.openUrl("https://vnclagoon.com/de/Nutzungsbedingungen/");
    else CommonUtil.openUrl("https://vnclagoon.com/terms/");
  }

  openPrivacyPolicy() {
    if (this.storedLanguage === "de") CommonUtil.openUrl("https://vnclagoon.com/de/datenschutzerklaerung/");
    else CommonUtil.openUrl("https://vnclagoon.com/data-privacy-policy/");
  }

  onClickReloadTasks() {
    this.taskRepo.reloadApp();
  }

  openInviteUserDialog() {
    let dialogStyle: any = {
      visibility: "visible",
      "width": "99%",
      "max-width": "400px",
      "min-height": "400px"
    };
    this.dialogService.showCustomDialog({
      component: UsersManagementComponent,
      providers: [],
      isModal: true,
      styles: dialogStyle,
      animate: false,
      clickOutsideToClose: true,
      classes: ""
    });
  }

  openArchiveUserDialog() {
    let dialogStyle: any = {
      visibility: "visible",
      "width": "99%",
      "max-width": "400px",
      "min-height": "400px"
    };
    this.dialogService.showCustomDialog({
      component: ArchiveUsersComponent,
      providers: [],
      isModal: true,
      styles: dialogStyle,
      animate: false,
      clickOutsideToClose: true,
      classes: ""
    });
  }

  openSaveSearchDialog() {
    const sDialog = this.matDialog.open(SaveSearchDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "vp-save-search-dialog",
    });
    sDialog.afterClosed().pipe(take(1)).subscribe($event => {
      if ($event && $event.query) {
        this.navigateSaveSearches($event.query);
      }
    });
  }

  navigateSaveSearches(query) {
    this.taskRepo.setSearchParams(query);
    // if already in search mode - then just show tasks
    if (this.isSearchMode) {
      if (this.isDetailView) {
        this.store.dispatch(new SetSelectAll(false));
        this.store.dispatch(new ResetSelectedTaskId());
        this.store.dispatch(new SetDetailView(false));
        this.router.navigate(["./", TasksConstants.ROUTE_SEARCH], { relativeTo: this.activated });
      }
      this.taskRepo.getTasks();
      this.taskRepo.getTasksStats();
    } else {
      this.router.navigate(["/task", TasksConstants.ROUTE_SEARCH], { queryParams: {} });
    }
  }

  changePassword() {
    if (this.config.get("isChangePasswordViaVNCd") && this.config.get("redirectPasswordChangeUrl")) {
      CommonUtil.openUrl(this.config.get("redirectPasswordChangeUrl"));
      return;
    }

    if (this.isOnline) {
      const sDialog = this.matDialog.open(ChangePasswordFormComponent, {
        maxWidth: "100%",
        autoFocus: false,
        panelClass: "vp-task-change-password-dialog",
      });
      sDialog.afterClosed().pipe(take(1)).subscribe($event => {
        if ($event) {
          this.logout();
        }
      });
    } else {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }
  }

  searchFocus() {
    this.ActiveSearchBar = true;
  }

  handleSearchTextFieldBlur(event: FocusEvent) {
    setTimeout(() => {
        this.isFocused = false;
    }, 200);
    this.ActiveSearchBar = false;
  }
}
